import httpService from "./http.service";

import { AuthService } from "./auth.service";
import { ApiService } from "./api.service";
import { HelperService } from "./helper.service";

export const authService            = new AuthService();
export const helperService          = new HelperService();

export const mapsApiService         = new ApiService('maps');
export const adminApiService        = new ApiService('admin');
export const usersApiService        = new ApiService('users');
export const stagesApiService       = new ApiService('stages');
export const videoApiService        = new ApiService('video');
export const teachersApiService     = new ApiService('teachers');
export const categoriesApiService   = new ApiService('categories');
export const eventsApiService       = new ApiService('events');
export const invitationApiService   = new ApiService('invitation');
export const dashboardApiService    = new ApiService('dashboard');
export const subscriptionApiService = new ApiService('subscription');
export const appSettingsService     = new ApiService('settings/appsettings');
export const paymentsApiService     = new ApiService('payments');
export const referralsApiService    = new ApiService('referrals');

export default {
    httpService,
    authService,
    helperService,
    mapsApiService,
    usersApiService,
    stagesApiService,
    dashboardApiService,
    subscriptionApiService,
    videoApiService,
    teachersApiService,
    categoriesApiService,
    eventsApiService,
    adminApiService,
    appSettingsService,
    paymentsApiService,
}