import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

import { CategorySelector, CopyToClipboardRow, RowDetails, SelectImageRow, TeacherSelector } from "./index";
import { $RtcFor, $RtcIf, $RtcIfElse } from "../dirctives";
import { AdminPasswordDialog, DeleteDialog } from "../dialogs";
import { helperService, mapsApiService } from "../../service";
import { globals } from "../../index";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Spinner } from "primereact/spinner";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';

import './components.scss';
import { InputNumber } from 'primereact/inputnumber';
import Moment from "react-moment";

import slugify from 'slugify';

const defImage = 'https://x.kinja-static.com/assets/images/logos/placeholders/default.png';

export const BaseCardDetails = (props) => {
    const { isCreateMode, withImage, imageKeyName, uploadUrl, apiService, itemName, showId, editTitle, createTitle, initItems, hideImageOnHeader, hideIdRow, CustomHeader } = props;

    const [selectedItem, setSelectedItem] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [errors, setErrors] = useState({});

    const [maps, setMaps] = useState([]);
    const [stages, setStages] = useState([]);

    const user = useSelector(state => state.user);

    const isTeacher = user.role === 'teacher';
    const isAdmin = user.role === 'admin';

    useEffect(() => {
        mapsApiService.get('').then(({data}) => { setMaps(data.data) });
    }, []);

    useEffect(() => {
        setSelectedItem(props.selectedItem);

        if (props.selectedItem != undefined) {
            if (props.selectedItem.mapId != undefined) {
                mapsApiService.get(`/stages/${props.selectedItem.mapId}`).then(({data}) => { setStages(data.data) });
            }
        }
    }, [props.selectedItem]);

    useEffect(() => {
        if (props.updateInnerSelectItem) {
            const {key, value} = props.updateInnerSelectItem;
            if (key && value) updateSelectedItem(key, value);
        }

    }, [props.updateInnerSelectItem]);

    const updateSelectedItem = (key, value, validations = []) => {
        if (validations && validations.length) {
            let isValid = true;
            for (let validation of validations) {
                if (!validation.isValidWhen(value)) {
                    isValid = false;
                    const err = validation.errMsg ? validation.errMsg : 'Invalid value.'
                    setErrors({...errors, [key]: err});
                    break;
                } else {
                    if (errors[key]) {
                        setErrors({...errors, [key]: null});
                    }
                }
            }
            if (!isValid) {
                return;
            }
        }

        const payload = {...selectedItem, [key]: value};

        setSelectedItem(payload);
    };

    const renderRow = (row, index) => {
        const { component, label, key, hideIf, tooltip, hideInCreateMode, isMandatoryFiled, onlyForAdmin, validations } = row;

        if (hideIf) return;
        if (onlyForAdmin && !isAdmin) return;
        if (hideInCreateMode && isCreateMode) return;

        const validProps = {};
        const ignoredKeys = ['pipe', 'hideIf', 'isMandatoryFiled', 'validations' ,'format', 'button'];

        for (let [key, value] of Object.entries(row)) {
            if (!ignoredKeys.includes(key)) {
                validProps[key] = value;
            }
        }

        switch (component) {
            case 'InputText':
                let itValue = selectedItem[key];
                if (itValue == undefined || itValue == null) { itValue = '' }

                return (
                    <RowDetails label={label} key={index} isMandatoryFiled={isMandatoryFiled} tooltip={tooltip}>
                        <InputText {...validProps} value={itValue} onChange={
                            (element) => {
                                if (!row.disabled) {
                                    updateSelectedItem(key, element.target.value, validations);

                                    let saveValue = element.target.value;

                                    if (props.itemName == 'MAP' && key == 'name') {
                                        mapsApiService.get('').then(({data}) => {
                                            setMaps(data.data);
                                        });

                                        for (let i = 0; i < maps.length; i++) {
                                            if (maps[i].name.toLowerCase() == saveValue.toLowerCase()) {
                                                setErrors({...errors, name: 'The course with this name already exists'});
                                            }
                                        }
                                    }

                                    if (props.itemName == 'Lesson' && key == 'name') {
                                        mapsApiService.get(`/stages/${props.selectedItem.mapId}`).then(({data}) => {
                                            setStages(data.data);
                                        });

                                        for (let i = 0; i < stages.length; i++) {
                                            if (stages[i].name.toLowerCase() == saveValue.toLowerCase()) {
                                                setErrors({...errors, name: 'The lesson with this name already exists'});
                                            }
                                        }
                                    }
                                    
                                }
                            }
                        } />

                        { errors[key] && <p className="error-msg">{errors[key]}</p> }
                    </RowDetails>
                )

            case 'InputNumber':
                let itnValue = selectedItem[key] || row.default;
                if (row.pipe) itnValue = row.pipe(itnValue);
                return (
                    <RowDetails label={label} key={index} isMandatoryFiled={isMandatoryFiled} tooltip={tooltip}>
                        <InputNumber
                            {...validProps}
                            value={itnValue ? itnValue : ''}
                            onChange={(e) => !row.disabled && updateSelectedItem(key, e.target.value, validations)}
                        />

                        { errors[key] && <p className="error-msg">{errors[key]}</p> }
                    </RowDetails>
                )

            case 'Button':
                return (
                    <ButtonRow {...{row, selectedItem, label, index}} />
                )

            case 'Dropdown':
                return (
                    <RowDetails label={label} key={index} isMandatoryFiled={isMandatoryFiled} tooltip={tooltip}>
                        <Dropdown
                            style={{minWidth: '50%'}}
                            {...validProps}
                            value={selectedItem[key] || row.default}
                            onChange={(e) => !row.disabled && updateSelectedItem(key, e.target.value)}/>
                    </RowDetails>
                )

            case 'MomentDate':
                return (
                    <RowDetails label={label} key={index} isMandatoryFiled={isMandatoryFiled} tooltip={tooltip}>
                        <Moment format={ row.format || 'DD/MM/YY h:mm a'}>{selectedItem[key] || row.default}</Moment>
                    </RowDetails>
                )

            case 'Checkbox':
                return (
                    <RowDetails label={label} key={index} isMandatoryFiled={isMandatoryFiled} tooltip={tooltip}>
                        <Checkbox checked={selectedItem[key] || row.default} onChange={(e) => !row.disabled && updateSelectedItem(key, e.target.checked)}></Checkbox>
                    </RowDetails>
                )

            case 'Spinner':
                return (
                    <RowDetails label={label} key={index} isMandatoryFiled={isMandatoryFiled} tooltip={tooltip}>
                        <Spinner
                            {...validProps}
                            value={selectedItem[key] || row.default}
                            onChange={(e) => !row.disabled && updateSelectedItem(key, e.target.value)}
                        />
                    </RowDetails>
                )
                
            case 'InputTextarea':
                let itaValue = selectedItem[key] || row.default;

                return (
                    <RowDetails label={label} key={index} isMandatoryFiled={isMandatoryFiled} tooltip={tooltip}>
                        <InputTextarea
                            {...validProps}
                            value={itaValue ? itaValue : ''}
                            onChange={(e) => !row.disabled && updateSelectedItem(key, e.target.value, validations)}
                        />

                        { errors[key] && <p className="error-msg">{errors[key]}</p> }
                    </RowDetails>
                )

            case 'CategorySelector':
                return (
                    <RowDetails label={label} key={index} isMandatoryFiled={isMandatoryFiled} tooltip={tooltip}>
                        <CategorySelector
                            value={selectedItem[key] || row.default}
                            onChange={(value) => !row.disabled && updateSelectedItem(key, value)}
                        />
                    </RowDetails>
                )

            case 'TeacherSelector':
                return (
                    <RowDetails label={label} key={index} isMandatoryFiled={isMandatoryFiled} tooltip={tooltip}>
                        <TeacherSelector
                            disabled={isTeacher}
                            value={isTeacher ? user.id : selectedItem[key] || row.default}
                            onChange={(value) => !row.disabled && updateSelectedItem(key, value)}/>
                    </RowDetails>
                )

            case 'ReferralUrl':
                return (
                    <RowDetails label={label} key={index}>
                        <CopyToClipboardRow value={selectedItem[key]}/>
                    </RowDetails>
                )

            case 'custom':
                const CustomComponent = row.renderFunc;

                return (
                    <CustomComponent key={index} isMandatoryFiled={isMandatoryFiled} tooltip={tooltip}
                                     {...{ selectedItem, updateSelectedItem, imageFile, setImageFile, initItems, label: label, itemKey: key, parentProps: props, stateFromSource: row.stateFromSource }} />
                )

            case 'ShowText':
                return (
                    <RowDetails label={label} key={index}>
                       {selectedItem[key]}
                    </RowDetails>
                )


            // Events

            case 'EventDate':
                return (
                    <RowDetails label={label} key={index} isMandatoryFiled={isMandatoryFiled}>
                        <Calendar id='basic' value={new Date(selectedItem[key])} dateFormat='dd/mm/yy' onChange={(event) => { updateSelectedItem(key, event.value) }} />
                    </RowDetails>
                )

            case 'ImageUploadDescription':
                return (
                    <RowDetails key={index}>
                        <p>For better user experience, please upload images with aspect ratio 16:10.<br/><br/>
                        Please avoid placing any text on the image.</p>
                    </RowDetails>
                )
        }
    }

    return (
        <div className={'base-card-details'}>
            {$RtcIfElse(selectedItem,
                (
                    selectedItem &&
                    <>
                        <div className="p-grid">
                            {$RtcIfElse(CustomHeader, (
                                CustomHeader &&
                                <CustomHeader {...{selectedItem, isCreateMode, editTitle, createTitle, imageKeyName}}/>
                            ), (
                                <CardHeader {...{ selectedItem, isCreateMode, editTitle, createTitle, imageKeyName, hideImageOnHeader }}/>
                            ))}

                            {/* {
                                !hideIdRow &&
                                <RowDetails showIf={!isCreateMode} label={'ID'}>
                                    <CopyToClipboardRow showIf={!isCreateMode && showId} value={selectedItem.id || selectedItem._id}/>
                                </RowDetails>
                            } */}

                            {$RtcFor(props.rows, renderRow)}

                            {withImage && <SelectImageRowWrapper {...{ selectedItem, isCreateMode, imageFile, imageKeyName, setImageFile, uploadUrl, apiService, updateSelectedItem, initItems }} /> }

                            <ActionsRow {...{ isCreateMode, selectedItem, imageFile, setImageFile, initItems, imageKeyName, apiService, isAdmin, errors, rows: props.rows, ...props.actions }}/>
                        </div>
                    </>
                ),
                (
                   
                    <div className={'please-select-msg'}>
                        <p>Please select {itemName} from the table to see his content.</p>
                    </div>
                )
            )}
        </div>
    )
}

BaseCardDetails.propTypes = { isCreateMode: PropTypes.bool, withImage: PropTypes.bool, showId: PropTypes.bool, imageKeyName: PropTypes.string, uploadUrl: PropTypes.string, itemName: PropTypes.string.isRequired, apiService: PropTypes.object, selectedItem: PropTypes.object };

BaseCardDetails.defaultProps = { showId: true, imageKeyName: 'image', isCreateMode: false, withImage: false }

const CardHeader = (props) => {
    const {selectedItem, isCreateMode, editTitle, createTitle, imageKeyName, hideImageOnHeader} = props;
    return (
        <div className="p-col-12 card-header">
            {$RtcIfElse(!isCreateMode, (
                <>
                    <h1 style={{position: 'absolute', left: 0}}>{editTitle}</h1>
                    {!hideImageOnHeader && <img src={selectedItem[imageKeyName || 'image'] || defImage} alt=""/>}
                </>
            ), (
                <h1>{createTitle}</h1>
            ))}
        </div>
    )
}

const SelectImageRowWrapper = (props) => {
    const { isCreateMode, imageFile, selectedItem, setImageFile, initItems, updateSelectedItem, imageKeyName, uploadUrl, apiService } = props;

    const afterFileUpload = async (image) => {
        updateSelectedItem(imageKeyName, image);
        initItems();
    };

    const { _id } = selectedItem;

    return (
        <SelectImageRow {...{ isCreateMode, imageFile, setImageFile, apiService: apiService, url: uploadUrl || `upload/${_id}`, afterFileUpload, }} />
    )
}

const ButtonRow = ({row, selectedItem, label, index}) => {
    const [showDialog, setShowDialog] = useState(null);
    const onVerify = (password) => {
        row.button.onClick && row.button.onClick(null, selectedItem, password)
    }

    return (
        <RowDetails label={label} key={index} isMandatoryFiled={false}>
            <Button disabled={row.disabled} label={row.button.label} className="p-button" onClick={ (e) => {
                if (row.askForPassword) { setShowDialog(true); } else { row.button.onClick && row.button.onClick(e, selectedItem, null) }
            }}/>

            {
                row.askForPassword && (
                    <AdminPasswordDialog header="Verify Admin password" text={'To execute this operation you have to provide secret admin password.'} onVerify={(password) => onVerify(password)} onHide={() => setShowDialog(false)} showDialog={showDialog} />
                )
            }
        </RowDetails>
    )
}

const ActionsRow = (props) => {
    const { rows, isAdmin, isCreateMode, selectedItem, initItems, imageFile, apiService, imageKeyName, hideDeleteBtn, showActionsOnlyToAdmin, askForPasswordOnDelete,
            onBeforeCreate, onAfterCreate, onBeforeSave, onAfterSave, onBeforeDelete, onAfterDelete, isSaveBtnDisabled, isDeleteBtnDisabled, isCreateBtnDisabled, errors } = props;

    const [showDeleteDialog, setShowDeleteDialog] = useState(null);
    const [isCreatingOrUpdate, setIsCreatingOrUpdate] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const [showDialog, setShowDialog] = useState(null);

    const onDelete = (password) => {
        let allowDelete = true;
        if (onBeforeDelete) allowDelete = onBeforeDelete(selectedItem);
        if (!allowDelete) return;

        let path = `${selectedItem.id}`;

        if (selectedItem.id == undefined) {
            path = selectedItem._id;
        }

        if (askForPasswordOnDelete && password) {
            path += `?password=${password}`;
        }

        apiService.delete(path)
            .then((response) => {
                globals.growlRef.show({severity: 'success', summary: `Item has been removed.`});
                initItems(true);
                if (onAfterDelete) onAfterDelete(response)
            });
    }

    const verifyMandatoryFiled = (payload) => {
        let isAllMandatoryFiledExists = true;
        const unValidFileds = [];

        const formDataHandler = () => {
            rows.forEach((row) => {
                if (row.isMandatoryFiled) {
                    if (!payload.has(row.key)) {
                        isAllMandatoryFiledExists = false;
                        unValidFileds.push(row);
                    }
                }
            })
        }

        const objectHandler = () => {
            rows.forEach((row) => {
                if (row.isMandatoryFiled) {
                    if (payload[row.key] === null || payload[row.key] === undefined || payload[row.key] === '') {
                        isAllMandatoryFiledExists = false;
                        unValidFileds.push(row);
                    }
                }
            })
        }

        if (payload instanceof FormData) {
            formDataHandler();
        } else {
            objectHandler()
        }

        if (!isAllMandatoryFiledExists) setErrMsg(`Please fill all the mandatory fields: ${unValidFileds.map(row => row.label).join(', ')}`);
        return isAllMandatoryFiledExists;
    }

    const onSave = async () => {
        if (!verifyMandatoryFiled(selectedItem)) return;

        setIsCreatingOrUpdate(true);

        let {id , _id} = selectedItem;
        id = id || _id;
        let payload = selectedItem;

        if (onBeforeSave) payload = onBeforeSave(payload);

        if (!verifyMandatoryFiled(payload)) {
            setIsCreatingOrUpdate(false);
            return;
        };

        if (payload.name != undefined) {
            if (payload.contentType == undefined) {
                payload.url = slugify(payload.name, { replacement: '-', lower: true })
            }
        }

        delete payload.subscription;

        apiService.put(`${id}`, payload)
            .then(response => {
                globals.growlRef.show({severity: 'success', summary: `Item has been updated.`});
                initItems();
                setIsCreatingOrUpdate(false);
                if (onAfterSave) onAfterSave(response);
            }).catch(error => {
                globals.growlRef.show({severity: 'error', summary: `Something went wrong.`});
                setIsCreatingOrUpdate(false);
        });
    }

    const onCreate = async () => {
        setIsCreatingOrUpdate(true);
        let form = new FormData();

        if (imageFile) {
            const compressedFile = await helperService.compressImage(imageFile);
            form.append(imageKeyName, compressedFile, imageFile.name);
        }

        if (selectedItem.url != undefined) {
            selectedItem.url = slugify(selectedItem.name, { replacement: '-', lower: true })
        }

        let payload = form;

        if (onBeforeCreate) payload = onBeforeCreate(selectedItem, form);

        if (!verifyMandatoryFiled(payload)) {
            setIsCreatingOrUpdate(false);
            return;
        };

        apiService.post('', payload)
            .then((response => {
                    globals.growlRef.show({severity: 'success', summary: `New Item has been created.`});
                    initItems(selectedItem);
                    setIsCreatingOrUpdate(false);
                    if (onAfterCreate) onAfterCreate(response);
                })
            ).catch(error => {
                globals.growlRef.show({severity: 'error', summary: `Something went wrong.`});
                setIsCreatingOrUpdate(false);
        })
    }

    const isFormValid = () => {
        if (!Object.keys(errors).length) return true;
        const errs = Object.keys(errors).filter(key => (errors[key] !== null && errors[key]));
        if (errs.length) return false;
        return true;
    }

    if (showActionsOnlyToAdmin && !isAdmin) return <></>;

    const makeTeacher = (password) => {
        let payload = {password: password};

        apiService.post(`verifyAdmin`, payload)
            .then(((response) => {
                if (response.data.verify == true) {
                    apiService.put(`${selectedItem.id}`, {role: 'teacher'})
                        .then((response) => {
                            globals.growlRef.show({severity: 'success', summary: `Item has been updated.`});
                            initItems();
                            setIsCreatingOrUpdate(false);
                        })
                        .catch((error) => { globals.growlRef.show({severity: 'error', summary: `Something went wrong.`}); });
                }
            }))
            .catch((error) => {})
    }

    return (
        <RowDetails>
            <div className={'action-btns'}>
                {errMsg && <p className="error-msg">{errMsg}</p>}
                {isCreatingOrUpdate &&
                <ProgressSpinner style={{width: '30px', height: '30px'}} strokeWidth="2" fill="#EEEEEE" animationDuration=".5s" />}

                {$RtcIf(!isCreateMode && !hideDeleteBtn, (
                    <Button disabled={isCreatingOrUpdate || isDeleteBtnDisabled} label="Delete" className="p-button-danger" onClick={() => setShowDeleteDialog(true)} />
                ))}


                {selectedItem.role == 'user' ? <Button label="Make Teacher" className="p-button-danger" onClick={() => setShowDialog(true)} /> : null} 

                <Button disabled={ isCreatingOrUpdate || isCreateBtnDisabled || isSaveBtnDisabled || !isFormValid() } label={ isCreateMode ? 'Create' : 'Save' } className="p-button-success" onClick={() => { isCreateMode ? onCreate() : onSave() }} />
            </div>

            <DeleteDialog
                header="Delete Item"
                text={ askForPasswordOnDelete ? 'To execute this operation you have to provide secret admin password.' : 'Are you sure you want to delete this item ?'}
                onDelete={(password) => onDelete(password)}
                askForPasswordOnDelete={askForPasswordOnDelete}
                onHide={() => setShowDeleteDialog(false)}
                showDialog={showDeleteDialog}
            />

            <AdminPasswordDialog header="Verify Admin password"
                text={'To execute this operation you have to provide secret admin password.'}
                onVerify={(password) => makeTeacher(password)}
                onHide={() => setShowDialog(false)}
                showDialog={showDialog}
            />

        </RowDetails>
    )
}