import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { $DurationToMinutes } from "../../../shared/pipes";
import { BaseCardDetails, RowDetails } from "../../../shared/components";
import { adminApiService, mapsApiService } from "../../../service";
import { maxLengthValidation } from "../../../shared/validations";
import { globals } from "../../../index";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import '../maps.scss';

class MapDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMapDraft: null,
        }
    }

    componentWillReceiveProps(nextProps, prevProps) {
        if (nextProps.selectedMap !== this.props.selectedMap) {
            const referralId = localStorage.getItem('referralId');

            if (nextProps.selectedMap != undefined) {
                nextProps.selectedMap.referralUrl = `https://www.relove.com/course/${nextProps.selectedMap.url}?refid=${referralId}&type=teacher`;
            } 

            this.setState({selectedMapDraft: nextProps.selectedMap});
        }
    }

    getRows() {
        return [
            { component: 'InputText', label: 'Name', key: 'name', isMandatoryFiled: true, validations: [maxLengthValidation(40)], tooltip: 'Title of course' },
            { component: 'ReferralUrl', label: 'Referral URL', key: 'referralUrl', disabled: true, hideIf: this.props.isCreateMode},
            { component: 'Checkbox', label: 'Published', key: 'publish', onlyForAdmin: true, hideInCreateMode: true, default: false, isMandatoryFiled: false },
            { component: 'Checkbox', label: 'Rejected', key: 'rejected', onlyForAdmin: true, hideInCreateMode: true, default: false, isMandatoryFiled: false },
            { component: 'Checkbox', label: 'Step by Step', key: 'isStepByStepContent', default: false, tooltip: 'if selected, the user must finish all lessons on step 1 in order to continue to lessons on step 2, etc.' },
            { component: 'Spinner', label: 'Steps free until', key: 'freeSteps', min: 0, showButtons: true, max: 100, type: 'number', default: 1, isMandatoryFiled: false,
                tooltip: 'If course is a premium, this number will represent the number of free stages before showing payment button.'
            },
            { component: 'Checkbox', label: 'Is Featured', key: 'isFeatured', default: false, onlyForAdmin: true, hideInCreateMode: true, isMandatoryFiled: false, tooltip: 'if selected, the course will be present in featured section.' },
            { component: 'Checkbox', label: 'Contains Nudity', key: 'nudity', default: false, onlyForAdmin: false, hideInCreateMode: false, isMandatoryFiled: false, tooltip: 'Courses that contain nudity will show only on web version of Relove' },
            { component: 'InputNumber', min: 0, max: 100, label: 'Score', key: 'score', onlyForAdmin: true, default: 0, isMandatoryFiled: false },
            { component: 'Dropdown', label: 'Price', key: 'price', placeholder: 'Select Price',
                options: [
                    {label: `Free`, value: -1},
                    {label: `9.99 USD`, value: 9.99},
                    {label: `24.99 USD`, value: 24.99},
                    {label: `49.99 USD`, value: 49.99},
                    {label: `74.99 USD`, value: 74.99},
                    {label: `99.99 USD`, value: 99.99},
                    {label: `149.99 USD`, value: 149.99},
                    {label: `199.99 USD`, value: 199.99},
                ],
                
                isMandatoryFiled: false, },
            { component: 'Dropdown', label: 'Difficulty', key: 'level', placeholder: 'Select level',
                options:  [ {label: 'Beginner', value: "0"}, {label: 'Intermediate', value: "1"}, {label: 'Advanced', value: "2"}, {label: 'Expert', value: "3"}, {label: 'Master', value: "4"} ], isMandatoryFiled: true },
            { component: 'InputText', label: 'Total video time', key: 'totalVideoTime', disabled: true, pipe: $DurationToMinutes, hideIf: this.props.isCreateMode },
            { component: 'InputTextarea', label: 'Description', validations: [maxLengthValidation(200)], key: 'description', placeholder: 'Description', id: 'textarea', rows: 3, cols: 30, autoResize: true, tooltip: 'Description of course' },
            {component: 'custom', renderFunc: StringsItemsHandlerComponent, stateFromSource: {}, label: 'Requirement', key: 'requirement'},
            {component: 'custom', renderFunc: StringsItemsHandlerComponent, stateFromSource: {}, label: 'For you if', key: 'forYouIf' },
            {component: 'custom', renderFunc: StringsItemsHandlerComponent, stateFromSource: {}, label: 'Student reviews', key: 'reviews'},
            {component: 'CategorySelector', label: 'Category', key: 'category', isMandatoryFiled: true},
            {component: 'TeacherSelector', label: 'Owner', key: 'ownerId', isMandatoryFiled: true},
            { component: 'Button', label: 'Notifications', onlyForAdmin: true, askForPassword: true, hideInCreateMode: true,
                button: { label: 'Notify users that course is published',
                onClick: (event ,selectedItem, adminPassword) => {
                    if (adminPassword) { adminApiService.post(`notify?password=${adminPassword}`, {text: `${selectedItem.name} course in now available on the app!`})
                        .then(({data}) => { globals.growlRef.show({severity: 'success', summary: `Notifications sent.`}); }).catch(err => { }) } } } },
        ]
    }


    render() {
        const {isCreateMode, user, initMaps, initStages} = this.props;
        const {selectedMapDraft} = this.state;

        return (
            <>
                <BaseCardDetails {...{
                    isCreateMode,
                    itemName: 'MAP',
                    imageKeyName: 'picture',
                    editTitle: 'COURSE Details',
                    createTitle: 'Create new COURSE',
                    apiService: mapsApiService,
                    withImage: true,
                    selectedItem: selectedMapDraft,
                    initItems: (item) => initMaps(item),
                    rows: this.getRows(),
                    actions: {
                        askForPasswordOnDelete: true,
                        onBeforeCreate: (selectedItem, form) => {
                            const isTeacher = user.role === 'teacher';
                            form.append('name', selectedItem.name);
                            form.append('price', selectedItem.price);
                            form.append('category', selectedItem.category);
                            form.append('level', selectedItem.level);
                            form.append('description', selectedItem.description);
                            form.append('ownerId', isTeacher ? user.id.toString() : ((selectedItem.ownerId && selectedItem.ownerId.toString()) || user.id.toString()));
                            return form;
                        },
                        onAfterDelete: (response) => initStages(),
                    }
                }} />
            </>
        );
    }
}


const mapStateToProps = state => ({
    user: state.user,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(MapDetails);


const StringsItemsHandlerComponent = (props) => {
    const {selectedItem, updateSelectedItem, itemKey, label, tooltip } = props;

    const randomId = (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    const tooltipId =`tooltip-row-details-${randomId}`;

    const [items, setItems] = useState(selectedItem[itemKey] || []);
    const [newItem, setNewItem] = useState('');

    useEffect(() => {
        setItems(selectedItem[itemKey]|| []);
    }, [props.selectedItem])

    const updateItems = (arr) => {
        setItems(arr);
        updateSelectedItem(itemKey, arr);
    }

    const renderList = (list) => {
        return list && list.map((n, i) => (
            <div className={'news-item'} key={i}>
                <InputText value={n} onChange={({target}) => { const arr = [...items]; arr[i] = target.value; updateItems(arr); }} />
                <Button disabled={false} label={'Remove'} className="p-button-danger" onClick={() => { const arr = [...items]; arr.splice(i, 1); updateItems(arr); }}/>
            </div>
        ))
    }

    return (
        <RowDetails label={label}>
            <div className="what-news">
                <div className="add-row">
                    <InputText onKeyDown={(event) => { if (event.key === 'Enter') { const arr = [...items]; arr.unshift(newItem); setNewItem(''); updateItems(arr); } }} value={newItem} onChange={({target}) => setNewItem(target.value)}></InputText>
                    <Button disabled={false} label={'Add'} className="p-button-success" onClick={() => { const arr = [...items]; arr.unshift(newItem); setNewItem(''); updateItems(arr); }} />
                </div>

                <div className="news-list"> {renderList(items)} </div>
            </div>
         </RowDetails>
    )
}