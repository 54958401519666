import React from 'react';
import './paymentsReferral.scss';
import { paymentsApiService} from '../../service';
import { PaymentsReferralTable } from './PaymentsReferralTable';
import { PaymentsReferralDetails } from './PaymentsReferralDetails';

class PaymentsReferralPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedPayment: null,
            payments: [],
            isCreateMode: false,
        }
    }

    onSelectionChange(selectedPayment) {
        this.setState({ selectedPayment })
    }

    componentDidMount() {
        this.initPayments();
    }

    initPayments(clearSelected = false) {
        paymentsApiService.get('referralPayments')
            .then(({data}) => {
                this.setState({payments: data.data})
                if (clearSelected) this.setState({selectedCategory: null});
            })
    }

    render() {
        const {selectedPayment, payments, isCreateMode } = this.state;
        return (
            <div className='payments-page'>
                <div className='p-grid'>
                    <div className='p-col-6'>
                        <PaymentsReferralTable {...{ payments, selectedPayment, onSelectionChange: (selectedCategory) => this.onSelectionChange(selectedCategory) }} />
                    </div>

                    <div className='p-col-6'>
                        <div className='card card-w-title' style={{'padding': '20px', minHeight: '100%'}}>
                            <PaymentsReferralDetails {...{ selectedPayment, isCreateMode, initPayments: (clearSelected) => this.initPayments(clearSelected) }}/>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default PaymentsReferralPage;