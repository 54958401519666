
import basConfig from './config';

const dev = {
    apiUrl: 'http://localhost:3001/api',
    baseUrl: 'http://localhost:3001',
}

const prod = {
    apiUrl: 'https://reloveapp.com/api',
    baseUrl: 'https://reloveapp.com',
}

let config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

if (process.env.NODE_ENV === 'production') {
    config = prod;
}

export default { MAX_ATTACHMENT_SIZE: 5000000, ...basConfig, ...config };