
import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RowDetails } from "./components";


export const DeleteDialog = (props) => {
    const [password, setPassword] = useState(null);

    const onDelete = () => {
        props.onDelete(password);
        setPassword(null);
        props.onHide();
    }
    
    const onCancel = () => {
        props.onHide();
    }

    const footer = (
        <div>
            <Button label="Delete" className="p-button-danger" icon="pi pi-check" onClick={onDelete}/>
            <Button label="Cancel" className="p-button-raised" icon="pi pi-times" onClick={onCancel}/>
        </div>
    );

    return (
        <Dialog
            header={props.header}
            footer={footer}
            visible={props.showDialog}
            style={{width: '50vw'}} modal={true}
            onHide={() => props.onHide()}>
            {props.text}
            {props.askForPasswordOnDelete &&
                <div>
                    <h5>Admin password</h5>
                    <InputText
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
            }
        </Dialog>
    )
}

export const AdminPasswordDialog = (props) => {
    const [password, setPassword] = useState(null);

    const onVerify = () => {
        props.onVerify(password);
        setPassword(null);
        props.onHide();
    }

    const onCancel = () => {
        props.onHide();
    }

    const footer = (
        <div>
            <Button label="Verify password" className="p-button-danger" icon="pi pi-check" onClick={onVerify}/>
            <Button label="Cancel" className="p-button-raised" icon="pi pi-times" onClick={onCancel}/>
        </div>
    );

    return (
        <Dialog
            header={props.header}
            footer={footer}
            visible={props.showDialog}
            style={{width: '50vw'}}
            modal={true}
            onHide={() => props.onHide()}>
            {props.text}

            <div>
                <h5>Admin password</h5>
                <InputText value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
        </Dialog>
    )
}
