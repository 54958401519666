import React, {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ImageCellTemplate} from "../../../shared/components";
import '../maps.scss';
import {Dropdown} from "primereact/dropdown";

export const StagesTable = (props) => {
    const tableRef = useRef(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [datatablePublishFilter, setDatatablePublishFilter] = useState('all');

    useEffect(() => {
        if (props.selectedMap) props.initStages(props.selectedMap.id);
        setSelectedStage(null);
    }, [props.selectedMap]);

    const onSelectionChange = (event) => {
        setSelectedStage(event.value);
        props.onSelectionChange(event.value);
    }
    const onCreateStage = () => {
        setSelectedStage({mapId: props.selectedMap.id});
        props.onSelectionChange({mapId: props.selectedMap.id}, true);
    }
    const publishTemplate = (rowData, column) => {
        return (
            <div style={{color: rowData.publish ? 'green' : 'red', fontWeight: 'bold'}}>
                {rowData.publish ? 'Published' : 'Waiting for review'}
            </div>
        )
    }

    const getTableTitle = () => props.selectedMap && props.selectedMap.name  ? (`Lessons of - ${props.selectedMap.name}`) : 'Lessons';

    const renderHeader = () => {
        return (
            <div className={'table-header'}>
                {getTableTitle()}
                <div>
                    <Button
                        disabled={!props.selectedMap}
                        icon="pi pi-plus"
                        label={ props.selectedMap && props.selectedMap.name ? "Create new Lesson for " + props.selectedMap.name : 'Create new Lesson'}
                        className="p-button-raised"
                        onClick={() => onCreateStage()}
                    />
                </div>
            </div>
        )
    };

    const imageTemplate = (rowData, column) => <ImageCellTemplate src={rowData.image} />

    const onPublishFilterChange = (event) => {
        if (event.value === 'all') {
            tableRef.current.filter(null, 'publish', 'equals');
        } else {
            tableRef.current.filter(event.value, 'publish', 'equals');
        }
        setDatatablePublishFilter(event.value)
    }

    let publishFilter = <Dropdown style={{width: '100%'}}
                                  placeholder="Filter by published state.."
                                  value={datatablePublishFilter}
                                  options={[{label: 'All', value: 'all'},{label: 'Publish', value: true}, {label: 'Waiting', value: false}]}
                                  onChange={ (e) => onPublishFilterChange(e)}/>
    return (

        <div className="stages-table">
            <div className="card card-w-title" style={{'padding': '0px'}}>
                <DataTable className="p-datatable-borderless"
                           value={props.stages.sort((a,b) => a.step - b.step)}
                           ref={tableRef}
                           selectionMode="single"
                           rowHover
                           header={renderHeader()}
                           paginator
                           emptyMessage="No Stages found"
                           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} maps"
                           rows={5}
                           responsive={true}
                           selection={selectedStage}
                           onSelectionChange={event => onSelectionChange(event)}
                >
                    <Column field="name" header="Name" filter sortable={true}/>
                    <Column field="image" header="Image" body={imageTemplate}/>
                    <Column field="publish" header="Published" filter filterElement={publishFilter} body={publishTemplate}/>
                </DataTable>
            </div>
        </div>
    )
}