import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useEffect, useState } from "react";
import { invitationApiService } from "../../service";
import Moment from "react-moment";
import { Button } from "primereact/button";
import { globals } from "../../index";

export const InviteTeacherTable = (props) => {
    const [invitations, setInvitations] = useState([]);

    useEffect(() => {
        initData();
    }, []);

    useEffect(() => {
        initData();
    }, [props.triggerInitData]);

    const initData = () => {
        invitationApiService.get('').then(({data}) => {
                setInvitations(data.data);
        });
    }

    const dateTemplate = (rowData, column) => {
        return (
            <Moment format={'DD/MM/YY'}>{rowData.createdDate}</Moment>
        )
    }

    const removeInvitation = (id) => {
        invitationApiService.delete(id).then(({data}) => {
            const arr = invitations.filter(item => item.id !== id);
            globals.growlRef.show({severity: 'success', summary: `Invitation to ${data.r.email} has been removed.`});
            setInvitations([...arr]);
        });
    }

    const copyLink = (link) => {
        navigator.clipboard.writeText(link);
        globals.growlRef.show({severity: 'success', summary: 'Link copied'})
    }

    const actionsTemplate = (rowData, column) => {
        rowData.link = rowData.link.replace('reloveapp', 'relove');

        return (
            <div className={'actions'}>
                <Button className="p-button-danger" onClick={ () => removeInvitation(rowData.id || rowData._id)}  icon="pi pi-trash" tooltip="Delete invitation" />
                <Button className="p-button-success" onClick={ () => copyLink(rowData.link)}  icon="pi pi-copy" tooltip="Copy invitation link" />
            </div>
        )
    }

    const {selectedTeacher} = props;

    return (
        <div className="card card-w-title p-fluid">
            <DataTable className="p-datatable-borderless" value={invitations} selectionMode="single" header={`Invitations - ${invitations.length}`} paginator={true} autoLayout={true} rows={5} responsive={true} selection={selectedTeacher} rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}>
                <Column field="name" header="Name" filter sortable={true}/>
                <Column field="email" header="Email" filter sortable={true}/>
                <Column field="status" header="Status" sortable={true}/>
                <Column field="createdDate" header="Date" body={dateTemplate} sortable={true}/>
                <Column field="actions" header="Actions" body={actionsTemplate} />
            </DataTable>
        </div>
    )
}
