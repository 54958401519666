import React, { useEffect, useState } from 'react';
import { BaseCardDetails, defImage, RowDetails } from '../../shared/components';
import { paymentsApiService } from '../../service';
import { InputText } from 'primereact/inputtext';
import Moment from 'react-moment';

export const PaymentsDetails = (props) => {
	const [selectedPayment, setSelectedPayment] = useState(null);

	useEffect(() => {
		setSelectedPayment(props.selectedPayment);
	}, [props.selectedPayment]);

	return (
		<BaseCardDetails
			{...{
				isCreateMode: false,
				itemName: 'Payments',
				editTitle: 'Payments Details',
				apiService: paymentsApiService,
				withImage: false,
				hideImageOnHeader: true,
				selectedItem: selectedPayment,
				initItems: (item) => props.initPayments(true),

				rows: [
					{ component: 'Checkbox', label: 'Paid to owner', key: 'is_paid_for_owner', onlyForAdmin: true, default: false, isMandatoryFiled: false, },
					{ component: 'custom', renderFunc: ContentDetailsRow, stateFromSource: {}, },
					{ component: 'custom', renderFunc: UserRow, stateFromSource: {}, onlyForAdmin: true },
					{ component: 'custom', renderFunc: OwnerRow, stateFromSource: {} },
					{ component: 'custom', renderFunc: PaymentAmountRow, stateFromSource: {}, },
					{ component: 'custom', renderFunc: PurchaseDateRow, stateFromSource: {}, },
				],

				actions: { showActionsOnlyToAdmin: true, hideDeleteBtn: true, },
			}}
		/>
	);
};

const CardInfo = (props) => {
	const { image, title, subtitle } = props;

	return (
		<div className={'payment-user-info'}>
			<img src={image || defImage} alt='' />
			<div className={'info'} style={{ justifyContent: subtitle ? 'space-around' : 'center' }} >
				{title && <span>{title}</span>}
				{subtitle && <span>{subtitle}</span>}
			</div>
		</div>
	);
};

const ContentDetailsRow = (props) => {
	const { selectedItem } = props;

	return (
		<RowDetails label={'Course name'}>
			<CardInfo image={selectedItem.content.picture} title={selectedItem.content.name} />
		</RowDetails>
	);
};

const UserRow = (props) => {
	const { selectedItem } = props;
	const user = selectedItem.user;

	return (
		<RowDetails label={'Client'}>
			 {user.email}
		</RowDetails>
	);
};

const OwnerRow = (props) => {
	const { selectedItem } = props;
	const fullName = `${selectedItem.owner.firstName} ${selectedItem.owner.lastName}`;

	return (
		<RowDetails label={'Course Owner'}>
			<CardInfo image={selectedItem.owner.picture} title={fullName} subtitle={selectedItem.owner.email} />
		</RowDetails>
	);
};

const PaymentAmountRow = (props) => {
	const { selectedItem } = props;

	return (
		<RowDetails label={'Paid'}>
			<InputText value={`${selectedItem.amount} ${selectedItem.currency}`} disabled />
		</RowDetails>
	);
};

const PurchaseDateRow = (props) => {
	const { selectedItem } = props;

	return (
		<RowDetails label={'Purchase Date'}>
			{selectedItem.createdDate && (
				<Moment format={'DD/MM/YY h:mm a'}>{selectedItem.createdDate}</Moment>
			)}
		</RowDetails>
	);
};