
import imageCompression from 'browser-image-compression';

export class HelperService {

    categoriesIdsToNames;
    constructor() {
        this.categoriesIdsToNames = {};
    }

    initCategoriesIdsToNames(categories) {
        categories.forEach(c => {
            this.categoriesIdsToNames[c.name] = c.id;
            this.categoriesIdsToNames[c.id] = c.name;
        });
    }

    async compressImage(file, options)  {
        const opt = {...{
            maxSizeMB: + (file.size / 1024 / 1024),
            maxIteration: 1,
            maxWidthOrHeight: 1280,
            useWebWorker: true,
            fileType: file.type || 'image/jpeg',
            onProgress: (n) => {}
        }, ...options};

        return await file;
    }
}
