import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { invitationApiService, teachersApiService, dashboardApiService } from "../service";
import { withRouter } from "react-router-dom";
import * as QueryString from "query-string"
import { stateObjectHandlerClassComp } from "../shared/functions";
import { RowDetails } from "../shared/components";
import { InputTextarea } from "primereact/inputtextarea";
import { Password } from 'primereact/password';
import { globals } from "../index";

class RegisterPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            teacher: {},
            socialLinks: {},
            password: '',
            confirm: '',
            token: '',
            error: '',
            terms: ''
        }

        this.updateTeacher = stateObjectHandlerClassComp.call(this, 'teacher');
        this.updateSocialLinks = stateObjectHandlerClassComp.call(this, 'socialLinks');
    }

    updateTeacher() {}

    goToLogin() {
        this.props.history.replace('/login');
    }

    componentDidMount() {
        const params = QueryString.parse(this.props.location.search);

        if (params.token) {
        //    go to access dene
            this.checkToken(params.token, (data) => {
                this.setState({token: params.token});
                this.initCurrentTeacherDetails(data);
            })
        } else {
            this.goToLogin();
        }

        dashboardApiService.get(`getTeachersTerms`).then(({ data }) => {
            this.setState({terms: data.data.text});
        });
    }

    initCurrentTeacherDetails(teacherDetails) {
        const {name, email} = teacherDetails;
        this.updateTeacher('firstName', name.split(' ')[0]);
        this.updateTeacher('lastName', name.split(' ')[1]);
        this.updateTeacher('email', email);
    }

    checkToken(token, cl) {
        invitationApiService.get(`/checkToken/${token}`)
            .then(({data}) => {
                if (data) {
                    cl(data.data);
                } else {
                    this.goToLogin()
                }
            }).catch(err => {
                this.goToLogin();
        });
    }

    getDisableState() {
        const {firstName , lastName, email} = this.state.teacher;
        const {password , confirm} = this.state;
        let hasError = false;

        return ((email && firstName && lastName && password && confirm ) && (password === confirm)) ;
    }

    register() {
        const {firstName , lastName, email, age, bio} = this.state.teacher;
        const {socialLinks} = this.state;
        const {password , token} = this.state;

        if (this.getDisableState()) {
            const payload = {
                firstName,
                lastName,
                email,
                age,
                bio,
                password,
                socialLinks,
            }

            teachersApiService.post(`register/${token}`, payload)
                .then(data => {
                       globals.growlRef.show({severity: 'success', summary: `Register Success!`, details: 'We will redirect to our login page now.'});
                       setTimeout(() => { this.goToLogin() }, 3000);
                }).catch(err => { });
        }

    }

    render() {
        const {firstName , lastName, email, age, bio, birthday} = this.state.teacher;
        const {facebook , instagram, website, youtube} = this.state.socialLinks;
        const {password , confirm} = this.state;

        return <div className="login-body" style={{backgroundColor:'#fdd8d2'}}>
            <div className="">
                <div className="p-grid p-nogutter">
                    <div className="p-col-12 p-lg-6" style={{paddingLeft: '5%', paddingTop:'3%', paddingRight: '5%'}}>
                        <img className="logo" alt="apollo-layout" src="assets/layout/images/relove1.png" />
                        <br/><br/><br/>
                        <h1>Welcome {firstName}</h1>
                        <br />
                        <br />
                        <h4>Relove Teachers Terms & Conditions</h4>
                        <InputTextarea rows={14} cols={70} value={this.state.terms} style={{width: '100%', border: '1px solid #a9a9a9', resize: 'none'}} />
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <div className="" style={{width: '90%', paddingLeft: '5%', maxWidth: '600px'}}>
                            <div style={{paddingTop:'15%', paddingBottom: '10%'}}>
                                <div className="p-grid p-fluid">
                                    <RowDetails label={'Email *'}>
                                        <InputText
                                            value={email}
                                            disabled
                                            type="text"
                                            placeholder="email"/>
                                    </RowDetails>

                                    <RowDetails label={'First name *'}>
                                        <InputText
                                            value={firstName}
                                            onChange={(e) => this.updateTeacher('firstName', e.target.value)}
                                            type="text"
                                            placeholder="First Name *"/>
                                    </RowDetails>

                                    <RowDetails label={'Last name *'}>
                                        <InputText
                                            value={lastName}
                                            onChange={(e) => this.updateTeacher('lastName', e.target.value)}
                                            type="text"
                                            placeholder="Last Name"/>
                                    </RowDetails>

                                    <RowDetails label={'Password *'}>
                                        <Password value={password}  onChange={(e) => this.setState({password: e.target.value})} />
                                    </RowDetails>

                                    <RowDetails label={'Confirm Password\u00A0*'}>
                                        <Password
                                            value={confirm}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value !== password) {
                                                    this.setState({error: 'password are not equal.'})
                                                } else {
                                                    this.setState({error: null})
                                                }
                                                this.setState({confirm: e.target.value});
                                            }} />
                                    </RowDetails>

                                    {/* <RowDetails label={'Date of birth *'}>
                                        <Calendar
                                            value={birthday}
                                            onChange={(e) => this.updateTeacher('birthday', e.value)}
                                            monthNavigator
                                            yearNavigator
                                            yearRange="1900:2030"
                                        />
                                    </RowDetails> */}

                                    {/* <RowDetails label={'Bio'}>
                                        <InputTextarea
                                            value={bio}
                                            onChange={(e) => this.updateTeacher('bio', e.target.value)}
                                            rows={4}
                                            cols={30}
                                            placeholder="Your Bio"
                                            autoResize={true} />
                                    </RowDetails> */}

                                    {/* <RowDetails label={'Facebook'}>
                                        <InputText
                                            value={facebook}
                                            onChange={(e) => this.updateSocialLinks('facebook', e.target.value)}
                                            type="text"
                                            placeholder="Facebook"/>
                                    </RowDetails> */}

                                    {/* <RowDetails label={'Instagram'}>
                                        <InputText
                                            value={instagram}
                                            onChange={(e) => this.updateSocialLinks('instagram', e.target.value)}
                                            type="text"
                                            placeholder="Instagram"/>
                                    </RowDetails> */}

                                    {/* <RowDetails label={'Website'}>
                                        <InputText
                                            value={website}
                                            onChange={(e) => this.updateSocialLinks('website', e.target.value)}
                                            type="text"
                                            placeholder="Website"/>
                                    </RowDetails> */}

                                    {/* <RowDetails label={'Youtube'}>
                                        <InputText
                                            value={youtube}
                                            onChange={(e) => this.updateSocialLinks('youtube', e.target.value)}
                                            type="text"
                                            placeholder="Youtube"/>
                                    </RowDetails> */}

                                    <RowDetails label={''}>
                                        <Button
                                            disabled={!this.getDisableState()}
                                            label="Register"
                                            icon="pi pi-check"
                                            onClick={() => this.register()}
                                            style={{backgroundColor: '#291630'}}/>

                                        { this.state.error && <span style={{color:'red'}}>{this.state.error}</span> }
                                    </RowDetails>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withRouter(RegisterPage);
