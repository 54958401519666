import {Column} from "primereact/column";
import React from "react";
import Moment from "react-moment";
import moment from "moment";


const DATE_FORMAT = 'DD/MM/YY';
export const CreatedDateColumn = {
     createdDateTemplate: (rowData, column) => {return (
            <div>
                { rowData.createdDate && <Moment format={DATE_FORMAT}>{rowData.createdDate}</Moment> }
            </div>
        )
    },

    dateFilter: (date, filter) => {
        if (!filter) return true;

        return (date && moment(date).format(DATE_FORMAT).includes(filter)) || false;
    }
}
