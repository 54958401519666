import React from 'react';
import {usersApiService} from '../../service/index';
import UserDetails from './UserDetails';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ImageCellTemplate } from '../../shared/components';
import moment from "moment";
import {Chart} from "primereact/chart";
import {CreatedDateColumn} from "../../shared/tableColumns";

class UsersPage extends React.Component {
    static navigationOptions = {title: null,};

    constructor(props) {
        super(props);
        this.state = {
            selectedUser: {},
            users: [],
            totalUsers: 0,
            inviteName: '',
            inviteEmail: '',
            error: '',
            displayDeleteWarning: false
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.initUsers();
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
    }

    initUsers(clearSelected = false) {
        usersApiService.get('')
            .then(({data}) => {
                this.setState({users: data.items, totalUsers: data.totalCount});
                if (clearSelected) this.setState({selectedUser: {}});
            })
    }

    imageTemplate(rowData, column) {
        return (
            <ImageCellTemplate src={rowData.picture} />
        )
    }

    onEditChange = (e, keyName) => {
        const editValue = e.target.value.trim();

        this.setState(prevState => ({
            selectedUser: {
                ...prevState.selectedUser,
                [`${keyName}`]: editValue
            }
        }));
    }

    onSelect = (event) => {
        this.setState(prevState => ({
            selectedUser: {
                ...prevState.selectedUser,
                picture: event.files
            }
        }));
    }

    render() {
        const { selectedUser } = this.state;

        return (
            <div className="users">

                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-6">
                            <div className="card card-w-title">
                                <DataTable className="p-datatable-borderless"
                                           value={this.state.users}
                                           selectionMode="single"
                                           header={`Total Users : ${this.state.totalUsers}`}
                                           paginator={true}
                                           autoLayout={true}
                                           rows={5}
                                           responsive={true}
                                           rowsPerPageOptions={[5,10,20,30,40,50]}
                                           selection={this.state.selectedUser}
                                           onSelectionChange={event => this.setState({selectedUser: event.value})}>
                                    <Column field="firstName" header="First name" filter sortable={true}/>
                                    <Column field="lastName" header="Last name" filter sortable={true}/>
                                    <Column field="email" header="email" filter sortable={true}/>
                                    <Column field="createdDate" header="Created Date" body={CreatedDateColumn.createdDateTemplate} filter filterMatchMode={'custom'} filterFunction={CreatedDateColumn.dateFilter} sortable={true}/>
                                    <Column field="picture" header="Picture" body={this.imageTemplate}/>
                                </DataTable>
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="card card-w-title" style={{'padding': '20px', minHeight: '100%'}}>

                                <UserDetails
                                    initUsers={(clearSelected) => this.initUsers(clearSelected)}
                                    selectedUser={this.state.selectedUser}
                                    onEditChange={this.onEditChange}
                                    onSelect={this.onSelect}
                                    onDelete={this.onDelete}
                                    onUpdate={this.onUpdate}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UsersPage;


const NewUsersChart = (props) => {
    const options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                }
            }]
        }
    }


    const getLabelsAndData = (users) => {
        const datesCounters = [];
        const usersArr = [...users];

        usersArr.sort((a, b) => a.createdDate - b.createdDate);
        usersArr.forEach((user) => {
            const m = moment(user.createdDate).format('DD/MM/YY');
            const dateCounter = datesCounters.find(d => d.date === m);
            if (!dateCounter) {
                datesCounters.push({
                    date: m,
                    counter: 1
                });
            } else {
                dateCounter.counter++;
            }
        })

        return [datesCounters.map(d => d.date), datesCounters.map(d => d.counter)];
    }

    const [labels, data] = getLabelsAndData(props.users);

    const lineData = {
        labels: labels,
        datasets: [
            {
                label: 'Users',
                data: data,
                fill: true,
                borderColor: '#03A9F4'
            },
        ],
    }
    return (
        <Chart type="line" options={options} data={lineData}/>
    )
}
