import React from 'react';
import {ImageCellTemplate, TeacherSelector} from '../../../shared/components';

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dropdown} from 'primereact/dropdown';

import '../maps.scss';

class MapsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            maps: [],
            selectedMap: null,
            datatablePublishFilter: 'all',
            datatablePaidOrFreeFilter: null,
            teacherFilter: '',
        }
    }

    onPublishFilterChange(event) {
        if (event.value === 'all') {
            this.dt.filter(null, 'publish', 'equals');
        } else if (event.value === 'rejected') {
            this.dt.filter(true, 'rejected', 'equals');
        } else {
            this.dt.filter(event.value, 'publish', 'equals');
        }

        this.setState({datatablePublishFilter: event.value});
    }

    filterPrice = (value, filter) => {
        if (filter === 'all') {
            return true;
        } else if (filter === 'premium') {
            return value > 0;
        } else if (filter === 'free') {
            return value < 0;
        } else {
            return true;
        }

        return true;
    }

    onPaidOrFreeFilterChange(event) {
        this.dt.filter(event.value, 'price', 'custom');
        this.setState({datatablePaidOrFreeFilter: event.value});
    }

    onSelectionChange(event) {
        this.setState({selectedMap: event.value});
        this.props.onSelectionChange(event.value);
    }

    imageTemplate(rowData, column) {
        return (
            <ImageCellTemplate src={rowData.picture}/>
        )
    }

    publishTemplate(rowData, column) {
        return (
            <div style={{color: rowData.publish ? 'green' : (rowData.rejected ? 'red' : 'orange'), fontWeight: 'bold'}}>
                {rowData.publish ? 'Published' : (rowData.rejected ? 'Rejected' : 'Waiting for review')}
            </div>
        )
    }

    paidOrFreeTemplate(rowData, column) {
        return (
            <div style={{color: rowData.price > 0 ?  'green' : 'orange' , fontWeight: 'bold'}}>
                {rowData.price > 0 ? 'Premium' : 'Free'}
            </div>
        )
    }

    teacherTemplate(rowData, column) {
        const teacher = this.props.teachers.find(teacher => teacher._id === rowData.ownerId);
        return (
            <div>
                {teacher ? (teacher.firstName + ' ' + teacher.lastName): ''}
            </div>
        )
    }

    onCreateMap() {
        this.setState({selectedMap: {}});
        this.props.onSelectionChange({}, true);
    }

    renderHeader() {
        return (
            <div className={'table-header'}>
                List of Courses - {this.props.maps && this.props.maps.length}
                <div>
                    <Button icon="pi pi-plus" label="Create new Course" className="p-button-raised" onClick={() => this.onCreateMap()}/>
                </div>
            </div>
        );
    }

    onTeacherChange(value) {
        this.dt.filter(value, 'ownerId', 'equals');
        this.setState({teacherFilter: value});
    }

    getMaps() {
        if (this.props.maps) {
            return this.props.maps.map(m => {
                if (!m.score) m.score = 1;
                return m;
            }).sort((a, b) => b.score - a.score).map(m => {
                if (m.score === 1) m.score = 0;
                return m;
            });
        }
        return [];
    }

    render() {
        let publishFilter = <Dropdown style={{width: '100%'}}
                                     placeholder="Filter by published state.."
                                     value={this.state.datatablePublishFilter}
                                     options={[{label: 'All', value: 'all'},{label: 'Publish', value: true}, {label: 'Waiting', value: false}, {label: 'Rejected', value: 'rejected'}]}
                                     onChange={ (e) => this.onPublishFilterChange(e)}/>

        let paidFreeFilter = <Dropdown style={{width: '100%'}}
                                     placeholder="Filter by price.."
                                     value={this.state.datatablePaidOrFreeFilter}
                                     options={[{label: 'All', value: 'all'}, {label: 'Premium', value: 'premium'}, {label: 'Free', value: 'free'}]}
                                     onChange={ (e) => this.onPaidOrFreeFilterChange(e)}/>

        let ownerFilter =    <TeacherSelector disabled={!this.props.isAdmin} value={this.state.teacherFilter} onChange={(value) => this.onTeacherChange(value)} />

        return (
            <div className={'maps-table'}>
                <div className="card card-w-title" style={{'padding': '0px'}}>
                    <DataTable
                        ref={(el) => this.dt = el}
                        className="p-datatable-borderless"
                        value={this.getMaps()}
                        selectionMode="single"
                        rowHover
                        header={this.renderHeader()}
                        paginator
                        emptyMessage="No Course found"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Courses"
                        rows={5}
                        rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
                        responsive={true}
                        selection={this.state.selectedMap}
                        onSelectionChange={event => this.onSelectionChange(event)}>
                            
                        <Column field="name" header="Name" filter sortable={true}/>
                        <Column field="image" header="Image" body={this.imageTemplate}/>
                        <Column field="publish" header="Published" filter filterElement={publishFilter} body={this.publishTemplate} />
                        <Column field="price" header="Paid / Free" filter filterElement={paidFreeFilter} body={this.paidOrFreeTemplate} filterFunction={this.filterPrice} />

                        {
                            this.props.isAdmin &&
                            <Column field="ownerId" header="Owner" filter filterElement={ownerFilter} body={ (rowData, col) => this.teacherTemplate(rowData, col)} />
                        }

                    </DataTable>
                </div>
            </div>
        );
    }
}

export default MapsTable;