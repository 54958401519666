import React from 'react';
import { connect } from "react-redux";
import './events.scss';
import { eventsApiService } from "../../service";
import { EventsTable } from "./EventsTable";
import { EventsDetails } from "./EventsDetails";


class EventsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedEvent: null,
            events: [],
            isCreateMode: false,
        }

        this.userRole = this.props.user.role;
        this.userId = this.props.user.id;
    }
    
    onSelectionChange(selectedEvent, mode) {
        this.setState({selectedEvent})
        this.setState({isCreateMode: mode});
    }

    componentDidMount() {
        this.initEvents();
    }

    initEvents(clearSelected = false) {
        eventsApiService.get('').then(({data}) => {
            console.log(data);

            switch(this.userRole) {
                case 'teacher':
                    const updateDate = data.data.filter((event) => {
                        return event.ownerId == this.userId;
                        // return event.ownerId == '63f07dd988b95ec38149c709';
                    })

                    this.setState({events: updateDate});
                    break;

                case 'admin':
                    this.setState({events: data.data});
                    break;
            }
            
            if (clearSelected) this.setState({selectedEvent: null});
        })
    }

    render() {
        const {selectedEvent, events, isCreateMode} = this.state;

        return (
            <div className='.events-page'>
                <div className="p-grid">
                    <div className="p-col-6">
                        <EventsTable {... { events, selectedEvent, onSelectionChange: (selectedEvent, mode) => this.onSelectionChange(selectedEvent, mode) }} />
                    </div>

                    <div className="p-col-6">
                        <div className="card card-w-title" style={{'padding': '20px', minHeight: '100%'}}>
                            <EventsDetails {...{ selectedEvent, isCreateMode, initEvents: (clearSelected) => this.initEvents(clearSelected) }}/>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state;
    return { user };
}

export default connect(mapStateToProps)(EventsPage);