import React from 'react';

import {usersApiService} from '../../service/index';
import {BaseCardDetails} from "../../shared/components";
import './users.scss';

class UserDetails extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            displayDeleteWarning: false,
            selectedUser: null,
            imageFile: null,
        }
    }

    componentWillReceiveProps(nextProps, prevProps) {
        if (nextProps.selectedUser !== this.props.selectedUser) {
            this.setState({selectedUser: nextProps.selectedUser});
        }
    }

    getRows() {
        return [
            { component: 'InputText', label: 'Email', key: 'email' },
            { component: 'InputText', label: 'Role', key: 'role', disabled: true },
            { component: 'InputText', label: 'First name', key: 'firstName' },
            { component: 'InputText', label: 'Last name', key: 'lastName' },
            { component: 'MomentDate', label: 'Created Date', key: 'createdDate', disabled: true },
            { component: 'MomentDate', label: 'Last login', key: 'lastLoginDate', disabled: true },
            { component: 'InputText', label: 'Number of sessions', key: 'numOfLogins', disabled: true },
            { component: 'InputText', label: 'Login type', key: 'loginType', disabled: true },
            { component: 'InputText', label: 'Referral', key: 'referralUsers', disabled: true },
            { component: 'InputText', label: 'Domain', key: 'domain', disabled: true },
        ]
    }

    render() {
        const { selectedUser } = this.state;
        const { initUsers } = this.props;

        return (
            <BaseCardDetails {...{
                itemName: 'USER',
                imageKeyName: 'picture',
                editTitle: 'User Details',
                createTitle: 'Create new User',
                apiService: usersApiService,
                withImage: true,
                selectedItem: selectedUser,
                initItems: () => initUsers(),
                rows: this.getRows(),
                actions: {
                    hideDeleteBtn: false,
                    askForPasswordOnDelete: true,
                    onBeforeSave: (selectedItem, form) => {
                        delete selectedItem.role;
                        return selectedItem;
                    },
                }
            }} />
        )
    }
}

export default UserDetails;
