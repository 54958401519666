import React, {useEffect, useState} from "react";
import {BaseCardDetails, RowDetails} from "../../shared/components";
import {appSettingsService} from "../../service";
import './settings.scss';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";


export const SettingsPage = (props) => {
    const [settings, setSettings] = useState({});

    useEffect(() => {
        initAppSettings()
    }, [])

    const initAppSettings = () => {
        appSettingsService.get()
            .then(({data}) => {
                setSettings(data);
            })
    }

    const getRows = () => ([
        {component: 'InputText', label: 'Into Video ID', key: 'introVideoId'},
        {component: 'InputText', label: 'Into Map ID', key: 'introMapId'},
        {
            component: 'InputText',
            label: 'Stripe Publishable Key - In case of change, should run migration for stripe customers in the server',
            key: 'stripePublishableKey'
        },
        {component: 'InputText', label: 'Apple Pay MerchantId', key: 'applePayMerchantId'},
        {component: 'InputText', label: 'Google Pay MerchantId', key: 'androidPayMerchantId'},
        {component: 'custom', renderFunc: WhatNews, stateFromSource: {}, label: 'News', key: 'news'},
        {component: 'custom', renderFunc: Prices, stateFromSource: {}, label: 'Prices', key: 'prices'},
        // {component: 'Checkbox',  label: 'Reviewed by editor', key: 'reviewed', onlyForAdmin: true, default: false, isMandatoryFiled: false},
        // {component: 'InputText', label: 'Display Name', key: 'displayName', disabled: isUploading},
        // {component: 'InputText', label: 'Type', key: 'contentType', disabled: true},
        // {component: 'InputText', label: 'Size', key: 'size', pipe: calcSize, disabled: true},
        // {component: 'InputText', label: 'Duration', key: 'duration', pipe: $DurationToMinutes, disabled: true},
        // {component: 'TeacherSelector', label: 'Owner', key: 'teacherId', disabled: isUploading},
        // {
        //     component: 'InputTextarea',
        //     label: 'Comments',
        //     key: 'comments',
        //     autoResize: true,
        //     rows: 3,
        //     cols: 30,
        //     disabled: isUploading
        // },
        // {component: 'custom', renderFunc: UsedInStagesRow, stateFromSource: {}},
        // {component: 'custom', renderFunc: CreatedDateRow, stateFromSource: {}},
    ])

    return (
        <div className="settings-page">

            <BaseCardDetails {...{
                itemName: 'SETTIGNS',
                isCreateMode: false,
                hideImageOnHeader: true,
                hideIdRow: true,
                editTitle: 'Settings - ONLY PUBLIC SETTING, DONT REGISTER HERE ANY SENSITIVE INFORMATION',
                apiService: appSettingsService,
                withImage: false,
                selectedItem: settings,
                initItems: () => initAppSettings(),
                rows: getRows(),
            }} />
        </div>
    )
}

const WhatNews = (props) => {
    const {selectedItem: settings, updateSelectedItem: setSettings} = props;

    const [news, setNews] = useState(settings.news || []);
    const [newNews, setNewNews] = useState('');

    useEffect(() => {
        setNews(settings.news || []);
    }, [props.selectedItem])

    const updateNews = (arr) => {
        setNews(arr);
        setSettings('news', arr);
    }

    const renderList = (list) => {
        return list.map((n, i) => (
            <div className={'news-item'} key={i}>
                <InputTextarea
                    value={n}
                    rows={5}
                    cols={30}
                    onChange={({target}) => {
                        const arr = [...news];
                        arr[i] = target.value;
                        updateNews(arr);
                    }}
                />
                <Button
                    disabled={false}
                    label={'Remove'}
                    className="p-button-danger"
                    onClick={() => {
                        const arr = [...news];
                        arr.splice(i, 1)
                        updateNews(arr);
                    }}/>
            </div>
        ))
    }

    return (
        <RowDetails label={'News'}>
            <div className="what-news">
                <div className="add-row">
                    <InputText
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                const arr = [...news];
                                arr.unshift(newNews);
                                setNewNews('');
                                updateNews(arr);
                            }
                        }}
                        value={newNews}
                        onChange={({target}) => setNewNews(target.value)}></InputText>
                    <Button
                        disabled={false}
                        label={'Add'}
                        className="p-button-success"
                        // isCreateMode ? this.onCreate() : this.onSave()
                        onClick={() => {
                            const arr = [...news];
                            arr.unshift(newNews);
                            setNewNews('');
                            updateNews(arr);
                        }}
                    />
                </div>

                <div className="news-list">
                    {renderList(news)}
                </div>
            </div>
        </RowDetails>
    )
}

const Prices = (props) => {
    const {selectedItem: settings, updateSelectedItem: setSettings} = props;
    const [prices, setPrices] = useState([]);
    const [price, setPrice] = useState(null);
    const [iosProductId, setIosProductId] = useState(null);
    const [androidProductId, setAndroidProductId] = useState(null);



    useEffect(() => {
        // setNews(['this is one news', 'this is sec']);
        removeValuesAfterAdding();
        setPrices(settings.prices || []);
    }, [props.selectedItem])

    const removeValuesAfterAdding = () => {
        setPrice('');
        setIosProductId('');
        setAndroidProductId('');
    }

    const updatePrices = (arr) => {
        setSettings('prices', arr);
    }

    const renderList = (list) => {
        return list.map((p, i) => (
            <div className={'price-row'} key={i}>
                <div className={'input-with-label'}>
                    <label>Price</label>
                    <InputText
                        tooltip={'price'}
                        placeholder={'price'}
                        value={p.price}
                        disabled/>
                </div>
                <div className={'input-with-label'}>
                    <label>Ios ProductId</label>
                    <InputText
                        tooltip={'Ios ProductId'}
                        placeholder={'Ios ProductId'}
                        value={p.iosProductId}
                        disabled/>
                </div>


                <div className={'input-with-label'}>
                    <label>Android ProductId</label>
                    <InputText
                        tooltip={'Android ProductId'}
                        placeholder={'Android ProductId'}
                        value={p.androidProductId}
                        disabled/>
                </div>

                <Button
                    disabled={false}
                    label={'Remove'}
                    style={{height: '50%', bottom: 0}}
                    className="p-button-danger"
                    // isCreateMode ? this.onCreate() : this.onSave()
                    onClick={() => {
                        const arr = [...prices];
                        arr.splice(i, 1)
                        updatePrices(arr);
                    }}/>
            </div>
        ))
    }

    const renderAddNewPrice = () => {

        return (
            <div className="price-row">
                <InputText
                    placeholder={'price'}
                    value={price}
                    onChange={({target}) => setPrice(target.value)}/>
                <InputText
                    placeholder={'Ios ProductId'}
                    value={iosProductId}
                    onChange={({target}) => setIosProductId(target.value)}/>
                <InputText
                    placeholder={'Android ProductId'}
                    value={androidProductId}
                    onChange={({target}) => setAndroidProductId(target.value)}/>

                <Button
                    disabled={false}
                    label={'Add'}
                    className="p-button-success"
                    onClick={() => {
                        if (price && iosProductId && androidProductId) {

                            const newPrice = {
                                price,
                                iosProductId,
                                androidProductId
                            }
                            const arr = [...settings.prices, newPrice];
                            updatePrices(arr);
                        }

                    }}
                />
            </div>
        )
    }

    return (
        <RowDetails label={'Prices'}>
            <div className="prices">
                {renderAddNewPrice()}
                <div className="news-list">
                    {renderList(prices)}
                </div>
            </div>
        </RowDetails>
    )
}

