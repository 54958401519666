import React, {Component, useState} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import {$RtcIfElse, $RtcModel} from "../shared/dirctives";
import {authService} from "../service";
import * as QueryString from "query-string"
import { withRouter } from "react-router-dom";


class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			isForgetPassword: false,
			isResetPasswordMode: false,
			token: '',
		}
	}

	componentDidMount() {
		const params = QueryString.parse(this.props.location.search);

		if (params && params.reset_password_token) {
			this.setState({isResetPasswordMode: true});
			this.setState({token: params.reset_password_token});
		}
	}

	onLogin = (username, password ) => {
		if (!username || !password) return;
		this.setState({isLoading: true})
		return authService.login(username,password)
			.then((isLoggedIn) => {
				const { history } = this.props;
				if (isLoggedIn) {
					history.push('/');
					this.setState({isLoading: false})
				} else {
				//	login failed
				}
			}).catch(err => {
				this.setState({isLoading: false})
			}).finally(() => {
				this.setState({isLoading: false})
			})
	}

	render() {
		const {username , password, isForgetPassword, isResetPasswordMode, token, isLoading} = this.state;
		return (
			$RtcIfElse(isLoading, (
			<div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fdd8d2' }}>
				<img src="assets/layout/images/heartpreloader.gif" alt=""/>
			</div>
		), (
			<div className="login-body" style={{backgroundColor:'#fdd8d2'}}>
				<div className="body-container">
					<div className="p-grid p-nogutter">
						<div className="p-col-12 p-lg-6 left-side">
							<div style={{position: 'relative', display: 'flex',alignItems: 'center'}} className={'logo-container'}>
								<img className="logo" alt="apollo-layout" src="assets/layout/images/relove1.png" />
							</div>
							<h1>Teacher Dashboard</h1>
							{ isResetPasswordMode ? (
								<p>Reset your password here</p>
							) : (
								<p color={'#553661'}>
									Welcome to the Relove Teacher Dashboard. <br /> <br />
									Please sign in using the login details provided to begin uploading your course. <br /> <br />

									<a href={'https://docs.google.com/forms/d/e/1FAIpQLSdbYqrbo28uoXu6wuSJbSGBikUAnE75w2KrDy5pKgZyurp4rg/viewform'}>
										<sapn style={{fontSize: 14, color: '#a985b7'}}>Don’t have login details? First you need to Apply</sapn>
									</a>
								</p>
							) }
						</div>
						<div className="p-col-12 p-lg-6 right-side">

							<div className="login-wrapper">
								{$RtcIfElse(isResetPasswordMode, (
									<ResetPassword
										token={token}
										onBackToLogin={() => {
											this.setState({isForgetPassword: false});
											this.setState({isResetPasswordMode: false});
										}}/>
								), (

									<>
										{$RtcIfElse(!isForgetPassword, (
											<div className="login-container">
												{/*<span className="title">Login</span>*/}
												<form>
													<div className="p-grid p-fluid" >
														<div className="p-col-12 p-field" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
															<label htmlFor="email" className="p-d-block" style={{padding: '5px'}}>Email</label>
															<InputText id="email" value={username} onChange={$RtcModel(this, 'username')} placeholder="Email"/>
														</div>
														<div className="p-col-12" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
															<label htmlFor="password" className="p-d-block" style={{padding: '5px'}}>Password</label>
															<InputText id="password" value={password} onChange={$RtcModel(this, 'password')} type="password" placeholder="Password"/>
														</div>
														<div className="p-col-6">
															<Button style={{backgroundColor: '#866c83'}} disabled={(!username || !password)} label="Sign In" icon="pi pi-check" onClick={() => this.onLogin(username,password)} />
														</div>
														<div className="p-col-6 password-container">
															<a onClick={() => this.setState({isForgetPassword: true})}>Forgot Password?</a>
														</div>
													</div>
												</form>

											</div>
										), (
											<ForgetPassword onBackToLogin={() => this.setState({isForgetPassword: false})} />
										))}
									</>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		))
		)


	}
}

export default withRouter(Login);


const ForgetPassword = (props) => {
	const [email, setEmail] = useState('');

	const sendForgetPassword = () => {
		if (email) {
			authService.requestPass(email)
				.then((response) => {
					props.onBackToLogin();
				})
		}
	}

	return (
		<div className="login-container forget-password">
			<span className="title">Forget Password</span>
			<div className="p-grid p-fluid">
				<div className="p-col-12">
					<InputText value={email} onInput={(e) => setEmail(e.target.value)} placeholder="Email"/>
				</div>

				<div className="p-col-6">
					<Button disabled={(!email)} label="Request password" icon="pi pi-check" onClick={() => sendForgetPassword()} />
				</div>
				<div className="p-col-6 password-container" onClick={() => props.onBackToLogin()}>
					<a>Back to login</a>
				</div>
			</div>
		</div>
	)
}

const ResetPassword = (props) => {
	const [password, setPassword] = useState('');
	const [confirm, setConfirm] = useState('');

	const sendResetPassword = () => {
		if (password && confirm && password === confirm) {
			authService.resetPass(password, confirm, props.token).then((response) => { props.onBackToLogin() });
		}
	}

	return (
		<div className="login-container forget-password">
			<span className="title">Reset Password</span>
			<div className="p-grid p-fluid">

				<div className="p-col-12">
					<InputText type={'password'} value={password} onInput={(e) => setPassword(e.target.value)} placeholder="Password"/>
				</div>

				<div className="p-col-12">
					<InputText type={'password'} value={confirm} onInput={(e) => setConfirm(e.target.value)} placeholder="Confirm Password"/>
				</div>

				<div className="p-col-6">
					<Button disabled={(!password || !confirm || (password !== confirm))} label="Reset password" icon="pi pi-check" onClick={() => sendResetPassword()} />
				</div>
			</div>
		</div>
	)
}
