import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './AppWrapper';
import { HashRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'prismjs/themes/prism-coy.css';
import { Growl } from "primereact/growl";
import { Provider } from 'react-redux';
import Store from "./redux/store";

export const globals = {
	growlRef: null,
}

ReactDOM.render(
	<HashRouter>
		<Growl ref={(el) => globals.growlRef = el} />
		<Provider store={Store}>
			<AppWrapper></AppWrapper>
		</Provider>
	</HashRouter>,

	document.getElementById('root')
);

serviceWorker.unregister();