import React, { useEffect, useRef, useState } from "react";
import { $DurationToMinutes, $MaxStringLength } from "../../../shared/pipes";
import { BaseCardDetails, CategorySelector, RowDetails, TeacherSelector, } from "../../../shared/components";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { OrderList } from "primereact/orderlist";
import { stagesApiService, videoApiService } from "../../../service";
import { OverlayPanel } from "primereact/overlaypanel";

import { objectToFormData } from "../../../shared/functions";
import { useSelector } from "react-redux";
import { globals } from "../../../index";
import { maxLengthValidation } from "../../../shared/validations";

import slugify from 'slugify';

const videosIdMapping = {};
export const StageDetails = (props) => {
	const [selectedStageDraft, setSelectedStageDraft] = useState(null);
	const [isCreateMode, setIsCreateMode] = useState(false);
	const [emitUpdateEvent, setEmitUpdateEvent] = useState({});
	const [changesSelectedVideosIdsMap, setChangesSelectedVideosIdsMap] = useState({});
	const [videos, setVideos] = useState([]);
	const [selectedVideos, setSelectedVideos] = useState([]);
	const user = useSelector((state) => state.user);

	useEffect(() => {
		setSelectedStageDraft(props.selectedStage);
		if (props.selectedStage) {
			setSelectedVideos(props.selectedStage.videos || []);
		}

		setChangesSelectedVideosIdsMap({});
	}, [props.selectedStage]);

	useEffect(() => {
		setIsCreateMode(props.isCreateMode);
		setChangesSelectedVideosIdsMap({});
	}, [props.isCreateMode]);

	useEffect(() => {
		loadDataOnlyOnce();
	}, []);

	const loadDataOnlyOnce = () => {
		const url = user.role === "teacher" ? `teacher/${user.id}` : "";
		videoApiService.get(url).then(({ data }) => {
			setVideos(data.items);
			data.items.forEach((video) => {
				videosIdMapping[video.id] = video;
			});
		});
	};

	const calcTotalVideoTime = (videos) => {
		let totalVideoTime = 0;
		videos.forEach((id) => {
			const v = videosIdMapping[id];
			if (v && v.duration) {
				totalVideoTime += v.duration;
			}
		});
		setEmitUpdateEvent({ key: "totalVideoTime", value: totalVideoTime });
	};

	const getRows = () => {
		return [
			{
				component: "InputText",
				label: "Name",
				key: "name",
				isMandatoryFiled: true,
				tooltip: "Title of lesson to show to users",
				validations: [maxLengthValidation(60)],
			},

			{ component: "Checkbox", label: "Published", key: "publish", onlyForAdmin: true, default: false, isMandatoryFiled: false, },

			{
				component: "Dropdown",
				label: "Difficulty",
				key: "level",
				placeholder: "Select level",
				options: [
					{ label: "Beginner", value: "0" },
					{ label: "Intermediate", value: "1" },
					{ label: "Advanced", value: "2" },
					{ label: "Expert", value: "3" },
					{ label: "Master", value: "4" },
				],
				isMandatoryFiled: true,
			},

			{
				component: "Spinner",
				label: "Step number",
				key: "step",
				min: 0,
				showButtons: true,
				max: 100,
				type: "number",
				isMandatoryFiled: true,
				tooltip: "Lessons with lower step number will show first in the list",
			},

			{
				component: "InputTextarea",
				label: "Description",
				key: "paragraph",
				placeholder: "Description",
				rows: 3,
				cols: 30,
				autoResize: true,
				tooltip: "Will show under the videos played in the lesson",
			},

			{
				component: "InputTextarea",
				label: "Comments",
				key: "comments",
				placeholder: "Comments",
				rows: 3,
				cols: 30,
				autoResize: true,
				tooltip: "Private, not shown to users",
			},

			{
				component: "InputText",
				label: "Celebration Text",
				key: "completedText",
				tooltip:
					"(Optional) Text to show to the user upon completion of this lesson, for example “Well done!”",
			},

			{
				component: "CategorySelector",
				label: "Category",
				key: "category",
				isMandatoryFiled: true,
			},

			{
				component: "TeacherSelector",
				label: "Owner",
				key: "ownerId",
				isMandatoryFiled: true,
			},

			{
				component: "custom",
				renderFunc: SelectVideosRow,
				stateFromSource: {
					videos,
					selectedVideos,
					setSelectedVideos,
					changesSelectedVideosIdsMap,
					setChangesSelectedVideosIdsMap,
					calcTotalVideoTime,
				},
			},

			{
				component: "custom",
				renderFunc: VideoOrderList,
				stateFromSource: { selectedVideos, setSelectedVideos },
			},

			{
				component: "custom",
				renderFunc: TotalTimeRow,
				stateFromSource: { calcTotalVideoTime },
			},
		];
	};

	return (
		<BaseCardDetails
			{...{
				isCreateMode,
				itemName: "Lesson",
				apiService: stagesApiService,
				withImage: true,
				selectedItem: selectedStageDraft,
				editTitle: "Lesson Details",
				createTitle: "Create new Lesson",
				updateInnerSelectItem: emitUpdateEvent,
				initItems: (item) => props.initStages(item && item.mapId),
				rows: getRows(),
				actions: {
					onBeforeCreate: (selectedItem, form) => {
						const isTeacher = user.role === "teacher";

						const payload = {
							...selectedItem,
							videos: selectedVideos,
							videosTracking: JSON.stringify(changesSelectedVideosIdsMap),
						};

						if (payload.name != undefined) {
								payload.url = slugify(payload.name, { replacement: '-', lower: true });
						}
	

						form = objectToFormData(payload, form, ["ownerId"]);
						form.append( "ownerId", isTeacher ? user.id.toString() : (selectedItem.ownerId && selectedItem.ownerId.toString()) || user.id.toString() );

						return form;
					},

					onBeforeSave: (payload) => ({ ...payload, videos: selectedVideos, videosTracking: changesSelectedVideosIdsMap, }),
				},
			}}
		/>
	);
};

const TotalTimeRow = (props) => {
	const { selectedItem } = props;
	const { calcTotalVideoTime } = props.stateFromSource;

	useEffect(() => {
		if (selectedItem.videos) calcTotalVideoTime(selectedItem.videos);
	}, []);

	return (
		<RowDetails label={"Total video time"}>
			<InputText
				value={$DurationToMinutes(selectedItem.totalVideoTime)}
				disabled
			/>
		</RowDetails>
	);
};

const SelectVideosRow = (props) => {
	const {
		videos,
		selectedVideos,
		setSelectedVideos,
		calcTotalVideoTime,
		changesSelectedVideosIdsMap,
		setChangesSelectedVideosIdsMap,
	} = props.stateFromSource;

	const onSelectedVideos = (event) => {
		const videos = event.value;

		if (videos.length < selectedVideos.length) {
			const removedId = selectedVideos.find((id) => !videos.includes(id));
			setChangesSelectedVideosIdsMap({
				...changesSelectedVideosIdsMap,
				[removedId]: "removed",
			});
		} else {
			const addedId = videos.find((id) => !selectedVideos.includes(id));
			setChangesSelectedVideosIdsMap({
				...changesSelectedVideosIdsMap,
				[addedId]: "added",
			});
		}
		setSelectedVideos(videos);
		calcTotalVideoTime(videos);
	};

	return (
		<RowDetails
			label={"Select videos"}
			tooltip={"Choose videos or mp3 files to be included in the lesson"}
		>
			<MultiSelect
				style={{ minWidth: "12em" }}
				filter={true}
				filterPlaceholder="Search"
				placeholder="Choose Videos"
				optionLabel="name"
				optionValue="id"
				value={selectedVideos}
				options={videos}
				onChange={(e) => onSelectedVideos(e)}
			/>
		</RowDetails>
	);
};

const VideoOrderList = (props) => {
	const [videoToWatch, setVideoToWatch] = useState(null);
	const { selectedVideos, setSelectedVideos } = props.stateFromSource;

	let videoOverlayPanel = useRef(null);
	// const {videoTemplate} = VideoOrderList;

	return (
		<RowDetails label={"Videos Order"}>
			<OrderList
				value={selectedVideos}
				dragdrop={true}
				itemTemplate={(videoId) => (
					<VideoTemplate {...{ videoId, setVideoToWatch, videoOverlayPanel }} />
				)}
				responsive={true}
				header="List of Selected Videos"
				listStyle={{ height: "auto", maxHeight: "40vh" }}
				onChange={(e) => setSelectedVideos(e.value)}
			/>

			<OverlayPanel
				ref={videoOverlayPanel}
				showCloseIcon={true}
				onHide={() => setVideoToWatch(null)}
				dismissable={true}
			>
				{videoToWatch && (
					<video
						width="320"
						height="240"
						controls
						src={videoToWatch.url}
						type={videoToWatch.contentType}
					/>
				)}
			</OverlayPanel>
		</RowDetails>
	);
};

const VideoTemplate = (props) => {
	const [displayName, setDisplayName] = useState("");
	const { videoId, setVideoToWatch, videoOverlayPanel } = props;

	useEffect(() => {
		videosIdMapping[videoId] &&
			setDisplayName(videosIdMapping[videoId].displayName);
	}, [props.videoId]);

	const saveDisplayName = (video) => {
		const { id } = video;
		const payload = { displayName };
		videoApiService.put(`${id}`, payload).then((response) => {
			globals.growlRef.show({
				severity: "success",
				summary: `Display name updated.`,
			});
			video.displayName = displayName;
		});
	};
	const playVideo = (e, v) => {
		setVideoToWatch(v);
		videoOverlayPanel.current.toggle(e);
	};
	return (
		<div className="p-clearfix">
			<div
				className={"p-grid p-justify-start p-align-center"}
				style={{
					fontSize: "14px",
					margin: "0",
					padding: "3%",
					position: "relative",
				}}
			>
				<Button
					icon="pi pi-caret-right"
					style={{ marginRight: "20px", pointerEvents: "all" }}
					className="p-button-success"
					onClick={(event) => playVideo(event, videosIdMapping[videoId])}
				/>
				<p>
					{videosIdMapping[videoId] &&
						$MaxStringLength(videosIdMapping[videoId].name, 20)}{" "}
					-
					{videosIdMapping[videoId] &&
						$DurationToMinutes(videosIdMapping[videoId].duration)}
				</p>

				<div className={"p-col-12 p-md-8"}>
					<InputText
						value={displayName || ""}
						onChange={(e) => setDisplayName(e.target.value)}
						type="text"
						placeholder="Display Name"
					/>
				</div>
				<div className={"p-col-12 p-md-4"}>
					<Button
						label={"Update"}
						className="p-button-success"
						onClick={() => saveDisplayName(videosIdMapping[videoId])}
					/>
				</div>
			</div>
		</div>
	);
};
