import httpService from "./http.service";
import environment from "../shared/env";
import Store from "../redux/store";
import { USER_CHANGE } from "../redux/constants";
import { globals } from "../index";
const USER_AUTH_DATA = 'auth_user';

export class AuthService {
    token = '';

    constructor() {
    }

    getBearerToken() {
        return `Bearer ${this.token}`;
    }

    isLoggedIn = () => Boolean(this.token);

    async login(email, password) {
        return httpService.post(`${environment.apiUrl}/auth/login`, {email, password})
            .then(async ({data}) => {
                const availableRoles = ['admin', 'teacher' ,'editor']
           
                if (data && availableRoles.includes(data.user.role)) {
                    return this.loginHandler(data);
                } else {
                    if (data.user.role === 'user') globals.growlRef.show({severity: 'error', summary: 'user is not allowed.'})
                    return false;
                }
            }).catch(err => {
                return false;
            });
    }

    loginHandler(data) {
        data.user.jwtToken = data.token;
        this.token = data.token;
        localStorage.setItem('referralId', data.user.referralId);
        localStorage.setItem(USER_AUTH_DATA, JSON.stringify({jwtToken: data.token, userId: data.user.id}));
        return this.initCurrentUser();
    }

    async initCurrentUser() {
        return new Promise(async (resolve, reject) => {
            try {
                const authData = JSON.parse(localStorage.getItem(USER_AUTH_DATA));
                if (authData) {
                    if (!this.token) this.token = authData.jwtToken;
                    let {data: currUser } = await httpService.get(`${environment.apiUrl}/users/current`);
                    await localStorage.setItem('rootPage', 'Home');
                    Store.dispatch({type: USER_CHANGE, payload: {...currUser}});
                    resolve(true);
                } else {
                    await localStorage.setItem('rootPage', 'Login');
                    resolve(false);
                }
            } catch (err) {
                localStorage.removeItem(USER_AUTH_DATA);
                reject(err);
            }

        })
    }

    async accessTokenHandler(config) {
        const accessToken = this.token;
        if (accessToken) {
            if (config.method !== 'OPTIONS') {
                config.headers.authorization = `Bearer ${accessToken}`;
            }
        }
    }

    async requestPass(email) {
        httpService.post(`${environment.apiUrl}/auth/request-pass`, {email});
    }

    async resetPass(password, confirmPassword, resetPasswordToken ) {
        httpService.post(`${environment.apiUrl}/auth/reset-pass`, { password, confirmPassword, resetPasswordToken });
    }
}