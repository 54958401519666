import React, {useState} from 'react';
import './profile.scss';
import {connect} from 'react-redux';
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Calendar} from "primereact/calendar";
import {FileUpload} from 'primereact/fileupload';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {Button} from "primereact/button";
import {authService, helperService, usersApiService} from "../../service";
import {ProgressSpinner} from "primereact/progressspinner";
import {$RtcIf, $RtcModel} from "../../shared/dirctives";
import {globals} from "../../index";

class ProfilePage extends React.Component {

    constructor(props) {
        super(props);
        const { user } = props;
        this.state = {
            id: user.id,
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email || '',
            birthday: user.birthday || '',
            age: user.age || '',
            bio: user.bio || '',
            facebook: this.getSocialMediaValue( user, 'facebook'),
            instagram: this.getSocialMediaValue( user, 'instagram'),
            website: this.getSocialMediaValue( user, 'website'),
            youtube: this.getSocialMediaValue( user, 'youtube'),
            paypal: this.getPaymentInfo(user,'paypal'),
        }
    }

    getSocialMediaValue(user, key) {
        return ( (user.socialLinks && user.socialLinks[key]) || '');
    }

    getPaymentInfo(user, key) {
        return ( (user.paymentInfo && user.paymentInfo[key]) || '');
    }

    async onSave() {
        const payload = this.state;
        payload.socialLinks = {
            facebook: this.state.facebook,
            instagram: this.state.instagram,
            website: this.state.website,
            youtube: this.state.youtube,
        };

        payload.paymentInfo = {
            paypal: this.state.paypal
        }

        try {
            await usersApiService.put('current/user', payload);
            await authService.initCurrentUser();
            globals.growlRef.show({severity: 'success', summary: `${this.props.user.firstName + ' ' + this.props.user.lastName} has been updated` });
        } catch (err) {

        }
    }


    render() {
        const { user } = this.props;
        const { firstName, lastName, email, birthday, age, bio, facebook, instagram, website, youtube, paypal } = this.state;

        const referralId = localStorage.getItem('referralId');

        const referralLink = `https://www.relove.com/?refid=${referralId}&type=teacher`;

        return (
            <div className={'profile-page'}>
                <div className="p-grid p-fluid">

                    <div className="p-col-12 p-grid">
                        <div className="p-col-6 card">
                            <div className="p-col-12">
                                <div className="card card-w-title">
                                    <h1>Basic Info</h1>
                                    <div className="p-grid">
                                        <div className="p-inputgroup p-md-4">
                                            <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
                                            <InputText value={firstName} onChange={$RtcModel(this, 'firstName')} placeholder="First name"/>
                                        </div>
                                        <div className="p-inputgroup p-md-4">
                                            <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
                                            <InputText value={lastName} onChange={$RtcModel(this, 'lastName')} placeholder="Last name"/>
                                        </div>
                                        <div className="p-inputgroup p-md-4">
                                            <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span>
                                            <InputText value={email} onChange={$RtcModel(this, 'email')} placeholder="Email"/>
                                        </div>

                                        <div className="p-inputgroup p-md-4">
                                            <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
                                            <InputText value={age} onChange={$RtcModel(this, 'age')} placeholder="Age"/>
                                        </div>
                                        <div className="p-inputgroup p-md-4">
                                            <span className="p-inputgroup-addon"><i className="pi pi-calendar" /></span>
                                            <Calendar
                                                value={ birthday && new Date(birthday)}
                                                monthNavigator={true}
                                                yearNavigator={true}
                                                yearRange="1950:2020"
                                                onChange={$RtcModel(this, 'birthday')}
                                                placeholder="birthday" />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="card card-w-title">
                                    <h1>Bio</h1>
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <InputTextarea  value={bio} onChange={$RtcModel(this, 'bio')} rows={4} cols={30} placeholder="Your Bio" autoResize={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="card card-w-title">
                                    <h1>Social Links</h1>
                                    <div className="p-grid">
                                        <div className=" p-md-6 ">
                                            <InputText value={instagram} onChange={$RtcModel(this, 'instagram')} placeholder="Instagram" />
                                        </div>
                                        <div className="p-md-6 ">
                                            <InputText value={facebook} onChange={$RtcModel(this, 'facebook')} placeholder="Facebook" />
                                        </div>
                                        <div className="p-md-6 ">
                                            <InputText value={youtube} onChange={$RtcModel(this, 'youtube')} placeholder="Youtube"/>
                                        </div>
                                        <span className="p-md-6 ">
                                    <InputText value={website} onChange={$RtcModel(this, 'website')} placeholder="Website"/>
                                </span>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="card card-w-title">
                                    <h1>Referral Link</h1>
                                    <div className="p-grid">
                                        <div className="p-md-12">
                                            <InputText value={referralLink} readOnly/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="card card-w-title">
                                    <h1>Payment Info</h1>
                                    <div className="p-grid">
                                        <div className=" p-md-6 ">
                                            <InputText value={paypal} onChange={$RtcModel(this, 'paypal')} placeholder="PayPal" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12" style={{display: 'flex', justifyContent: 'center'}}>
                                <div className="p-col-4">
                                    <Button
                                        label="Save"
                                        onClick={this.onSave.bind(this)}
                                        style={{marginBottom:'10px'}}
                                        className="p-button-success" />
                                </div>
                            </div>

                        </div>
                        <div className="p-col-6" style={{paddingTop:'0'}}>
                            <CropImageComponent user={user} />
                        </div>
                    </div>

                </div>

                <div className="p-grid p-fluid">

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);

const cropper = React.createRef(null);
const uploader = React.createRef(null);

const CropImageComponent = (props) => {
    const [croppedImage, setCroppedImage] = useState('');
    const [file, setFile] = useState(null);
    const [newSelectedImage, setNewSelectedImage] = useState('');
    const [isCropChange, setCropChange] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const convertBase64ToFile = (image) => {
        const byteString = atob(image.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
        }

        const newBlob = new Blob([ab], {
            type: 'image/jpeg',
        });

        return newBlob;
    }

    const blobToFile = (theBlob, fileName) => {
        return new File([theBlob], fileName , {type: theBlob.type});
    }

    const onImageSelect = async (event) => {
        const compressedFile = await helperService.compressImage(event.files[0]);
        setFile(compressedFile);
        const objectUrl = window.URL.createObjectURL(compressedFile);
        setCroppedImage(objectUrl);
        setNewSelectedImage(objectUrl);
        setCropChange( true);
    }

    const _crop = () => {
        if (cropper) {
            setCroppedImage( cropper.current.getCroppedCanvas().toDataURL());
        }
    }
    const onCropEnd = (e) => {
        if (!isCropChange) {
            setCropChange( true);
        }
    }
    const onUpload = async (e) => {
        if (croppedImage) {
            setShowSpinner(true)
            const name = (file && file.name) || user.picture.split('_')[(user.picture.split('_').length)];
            const croppedFile = blobToFile( convertBase64ToFile(croppedImage) , name );
            const compressedFile = await helperService.compressImage(croppedFile);
            const formData = new FormData();
            formData.append('file', compressedFile, name);

            try {
                await usersApiService.post(`/upload/${user.id}`, formData);
                await authService.initCurrentUser();
                setCropChange(false);
                setCroppedImage(null);
                setNewSelectedImage(null);
                setFile(null);
                uploader.current.clear();
                setShowSpinner(false);
                globals.growlRef.show({severity: 'success', summary: 'Image uploaded!'})

            } catch (err) {

            }

            delete user.picture;
        }
    }
    const { user } = props;

    return (
            <div className="p-col-12" style={{position:'relative', paddingTop:'0'}}>

                <div className="card card-w-title">
                    <h1>Profile image</h1>
                    <div className="p-grid">
                        <div className="p-col-4">
                            <FileUpload ref={uploader} mode="basic" customUpload={true} name="file" onSelect={ (e) => onImageSelect(e)} accept="image/*" />
                        </div>
                        <div className="p-col-4">
                            <Button disabled={ ((!isCropChange && !file) || showSpinner) } label="Upload" onClick={onUpload} style={{marginBottom:'10px'}} className="p-button-success" />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-6">
                            <Cropper ref={cropper} src={newSelectedImage || user.picture} style={{height: 250, width: '100%'}} viewMode={1} background={false} preview={'#preview'} cropend={onCropEnd} guides={true} crop={_crop} />
                        </div>
                        <div className="p-col-6" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {$RtcIf(showSpinner ,(
                                <div className="spinner-container">
                                    <ProgressSpinner style={{width: '50px', height: '50px', backgroundColor: 'transparent'}} strokeWidth="4"  animationDuration=".7s"/>
                                </div>
                            ))}

                            { (croppedImage || user.picture) && <img src={ isCropChange ? croppedImage : user.picture} width={150} height={150} style={{borderRadius: '50%', border: '1px solid'}} alt=""/> }
                        </div>
                    </div>
                </div>
            </div>
    );
}



