import React from 'react';
import { appSettingsService, mapsApiService } from "../../service";
import MapsTable from "./components/MapsTable";
import MapDetails from "./components/MapDetails";
import { StagesTable } from "./components/StagesTable";
import { StageDetails } from "./components/StageDetails";
import { USER_LOGOUT } from "../../redux/constants";
import { connect } from "react-redux";
import { updateMaps } from "../../redux/actions";
import './maps.scss';

class MapsPage extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            maps: [],
            stages: [],
            selectedMap: null,
            selectedStage: null,
            imageFile: null,
            isCreateMode: false,
            isStageInCreateMode: false,
            prices: []
        }
    }

    componentDidMount() {
        this.initMapsData();
        this.initPrices();
    }

    initMapsData() {
        const isTeacher = this.props.user.role === 'teacher';
        const id = this.props.user.id;
        const url = isTeacher ? `teacher/${id}` : '';

        mapsApiService.get(url)
            .then(({data}) => {
                this.props.updateMaps(data.data);
                this.setState({maps: data.data});
                this.setState({selectedMap: null});
            });
    }

    initPrices() {
        appSettingsService.get('prices')
            .then(({data}) => {
                this.setState({prices: data});
            })
    }

    initStagesData(mapId) {
        const {selectedMap} = this.state;
        if (mapId || (selectedMap && selectedMap.id)) {
            const id = mapId || selectedMap.id;
            mapsApiService.get(`stages/${id}`)
                .then(({data}) => {
                    this.setState({stages: data.data});
                    this.setState({selectedStage: null});
                });
        }
    }

    onMapSelectionChange(map, isCreateMode = false) {
        this.setState({isCreateMode: isCreateMode});
        this.setState({selectedMap: map});
        this.setState({selectedStage: null});

        if (isCreateMode) {
            this.setState({stages: []});
        }
    }

    onStageSelectionChange(stage, mode = false) {
        this.setState({isStageInCreateMode: mode});
        this.setState({selectedStage: stage});
    }

    render() {
        const {selectedMap,selectedStage, maps, isCreateMode, isStageInCreateMode} = this.state;

        return (
            <div className={'maps-page'}>
                <div className="p-grid">
                    <div className="p-col-6">
                        <MapsTable
                            maps={maps.map(m => {
                                if (!m.publish) m.publish = false;
                                if (!m.rejected) m.rejected = false;
                                return m;
                            })}
                            isAdmin={this.props.user.role === 'admin'}
                            teachers={this.props.teachers}
                            onCreateMap={() => this.setState({isCreateMode: true})}
                            onSelectionChange={(selectedMap, isCreateMode) => this.onMapSelectionChange(selectedMap, isCreateMode)} />
                    </div>
                    <div className="p-col-6">
                        <div className="card card-w-title" style={{'padding': '20px', minHeight: '100%'}}>
                            <MapDetails
                                isCreateMode={isCreateMode}
                                initMaps={(item) => this.initMapsData(item)}
                                initStages={() => this.initStagesData()}
                                selectedMap={selectedMap}
                                prices={this.state.prices}
                            />
                        </div>
                    </div>
                    <div className="p-col-6">
                        <StagesTable
                            initStages={(mapId) => this.initStagesData(mapId)}
                            stages={this.state.stages}
                            selectedMap={selectedMap}
                            onSelectionChange={(selected, isCreateMode) => this.onStageSelectionChange(selected, isCreateMode)}
                             />
                    </div>
                    <div className="p-col-6">
                        <div className="card card-w-title" style={{'padding': '20px', minHeight: '100%'}}>
                            <StageDetails
                                isCreateMode={isStageInCreateMode}
                                initStages={(mapId) => this.initStagesData(mapId)}
                                selectedStage={selectedStage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, teachers } = state
    return { user, teachers };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({type: USER_LOGOUT}),
        updateMaps: (payload) => dispatch(updateMaps(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapsPage);