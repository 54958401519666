import React, {useEffect, useState} from "react";
import {VideoTable} from "./VideosTable";
import {VideoDetails} from "./VideoDetails";

import {authService, mapsApiService, videoApiService} from "../../service";
import {globals} from "../../index";
import {useDispatch, useSelector} from "react-redux";
import environment from '../../shared/env.js';
import {updateMaps, updateUploadProgress} from "../../redux/actions";
import Gallery from 'react-fine-uploader'
import FineUploaderTraditional from 'fine-uploader-wrappers';
import './content.scss';
import 'react-fine-uploader/gallery/gallery.css';

const baseUrl = environment.apiUrl;
const URL = `${baseUrl}/video`;

let uploaderRef;
let audioUploaderRef;

export const ContentPage = (props) => {
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [uploader, setUploader] = useState(null);
    const [audioUploader, setAudioUploader] = useState(null);
    const [mapsIdsToDetails, setMapsIdsToDetails] = useState({});
    const user = useSelector(state => state.user);
    const isTeacher = user.role === 'teacher';
    const dispatcher = useDispatch();
    const maps = useSelector(state => state.maps);

    useEffect(() => {
        initVideos();

        if (maps && maps.length) {
            initMapsIdsToDetails(maps);
        }

        uploaderRef = initUploader();
        audioUploaderRef = initUploader(true);
        setAudioUploader(audioUploaderRef)
        setUploader(uploaderRef);
    }, []);

    const initMapsIdsToDetails = (maps) => {
        if (maps && maps.length) {
            const details = {};
            maps.forEach(map => {
                details[map._id] = map;
            })
            setMapsIdsToDetails(details);
        }
    }
    const initVideos = (clearSelected) => {
        const responseHandler = (data) => {
            setVideos(data.items);

            if (clearSelected) {
                setSelectedVideo(null);
            }
        }

        const url = isTeacher ? `teacher/${user.id}` : '';

        videoApiService.get(url)
            .then(({data}) => {
                responseHandler(data);

                if (!maps || !maps.length) {
                    initMapsData();
                }
            })
    }

    const initMapsData = () => {
        const isTeacher = user.role === 'teacher';
        const id = user.id;
        const url = isTeacher ? `teacher/${id}` : '';

        mapsApiService.get(url)
            .then(({data}) => {
                dispatcher(updateMaps(data.data))
                initMapsIdsToDetails(data.data);
            });
    }

    const updateProgressPercent = (percent) => {
        dispatcher(updateUploadProgress({percent}))
    }

    const onUpload = () => {
        updateProgressPercent(0);
        initVideos();
    }

    const getUrl = (type =  '') => {
        const isTeacher = user.role === 'teacher';
        return isTeacher ? `${URL}?userId=${user.id}&contentType=${type}` : `${URL}?&contentType=${type}`;
    }

    const getTokenToHeader = () => {
        return authService.getBearerToken();
    }

    const MbToBytes =(mb) => 1024 * 1024 * mb;
    const initUploader = (isAudio = false) => {
        const type = isAudio ? 'audio' : 'video';
        return new FineUploaderTraditional({
            options: {
                template: 'qq-template-manual-trigger',
                autoUpload: true,
                multiple: false,
                chunking: { enabled: true, partSize: MbToBytes(2), concurrent: { enabled: false }, },
                request: { endpoint: getUrl(type), customHeaders: { "Authorization": getTokenToHeader() } },
                cors: { expected: true, sendCredentials: true },
                validation: { sizeLimit: MbToBytes((1024 * 3)) },
                resume: { enabled: true },
                retry: { enableAuto: true, showButton: true },

                callbacks: {
                    onError: (id, name, err) => {

                    },

                    onCancel: (id, name, err) => {
             
                    },

                    onUploadChunk: (id, name, chunk) => {
             
                    },

                    onSubmit: (id, name) => {
                        if (isAudio) {
                            audioUploaderRef.methods.setEndpoint(getUrl(type));
                        } else {
                            uploaderRef.methods.setEndpoint(getUrl(type));
                        }
                    },

                    onComplete: (id, name, response) => {
                        setTimeout(() => {
                            onUpload()
                        }, 1000)
                    },

                    onProgress: (id, name, uploadedBytes, totalBytes) => {

                    }
                }
            }
        })
    }

    const ChooseVideosFiles = <span>Choose files</span>
    const ChooseAudioFiles = <span>Choose Audios files</span>

    return (
        <div className="content-page">
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title" style={{'padding': '20px', minHeight: '100%'}}>
                        <h1>Upload files</h1>
                        <h4>For the best user experience, please upload video files with resolution - 1920x1080</h4>
                        <h4>Maximum file size - 1.5 GB </h4>
                        { uploader && <Gallery fileInput-children={ChooseVideosFiles} fileInput-accept='video/*' uploader={uploader}/> }
                    </div>
                </div>

                <div className="p-col-12">
                    <VideoTable mapsIdsToDetails={mapsIdsToDetails} videos={videos} onVideoSelected={(video) => setSelectedVideo(video)} />
                </div>

                <div className="p-col-6">
                    <div className="card card-w-title" style={{'padding': '20px', minHeight: '100%'}}>
                        <VideoDetails  {...{selectedVideo, mapsIdsToDetails, initVideos}} />
                    </div>
                </div>

            </div>
        </div>
    );
}