import React from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ImageCellTemplate } from '../../shared/components';

export const CategoriesTable = (props) => {
    const {categories, selectedCategory, onSelectionChange} = props;

    const imageTemplate = (rowData, column) => {
        return (
            <ImageCellTemplate src={rowData.image} />
        )
    }

    const onCreate = () => {
        onSelectionChange({}, true);
    }

    const renderHeader = () => {
        return (
            <div className={'table-header'}>
                List of Categories - {categories.length}
                <div>
                    <Button
                        icon="pi pi-plus"
                        label="Create new Category"
                        className="p-button-raised"
                        onClick={() => onCreate()}/>
                </div>
            </div>
        );
    }
    return (
        <div className="categories-table">
            <div className="card card-w-title" style={{'padding': '0px'}}>
                <DataTable className="p-datatable-borderless"
                           value={categories}
                           selectionMode="single"
                           rowHover
                           header={renderHeader()}
                           paginator={true}
                           rows={5}
                           rowsPerPageOptions={[5,10,20,30,40,50]}
                           responsive={true}
                           selection={selectedCategory}
                           onSelectionChange={event => onSelectionChange(event.value)}>
                    <Column field="name" header="Name" filter sortable={true}/>
                    <Column field="image" header="Image" body={imageTemplate}/>
                </DataTable>
            </div>
        </div>

    )
}