import React, {useEffect, useState} from 'react';
import {BaseCardDetails, RowDetails} from "../../shared/components";
import {teachersApiService} from "../../service";
import {InputText} from "primereact/inputtext";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Calendar} from "primereact/calendar";
import './teachers.scss';

export const TeacherDetails = (props) => {
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const {initTeachers} = props;

    useEffect(() => {
        setSelectedTeacher(props.teacher);
    }, [props.teacher]);

    const getRows = () => ([
        {component: 'InputText', label: 'Email', key: 'email'},
        {component: 'InputText', label: 'First name', key: 'firstName'},
        {component: 'InputText', label: 'Last name', key: 'lastName'},
        {component: 'InputText', label: 'Title', key: 'description'},
        {component: 'InputText', label: 'Age', key: 'age'},
        {component: 'InputTextarea', label: 'Bio', key: 'bio', autoResize: true, rows: 6},
        {component: 'InputText', label: 'Number of videos', key: 'sessions', disabled: true, default: '0'},
        {component: 'InputText', label: 'Storyframe integration', placeholder: 'Storyframe embed id', key: 'storyframeEmbedId', onlyForAdmin: true },
        {component: 'Checkbox', label: 'Published', key: 'published', onlyForAdmin: true },
        {component: 'custom', renderFunc: BirthdayRow, stateFromSource: {}},
        {component: 'custom', renderFunc: SocialLinksRow, stateFromSource: {}},
        {component: 'custom', renderFunc: PaymentInfoRow, stateFromSource: {}},
    ])

    return (
        <BaseCardDetails {...{
            itemName: 'TEACHER',
            imageKeyName: 'picture',
            editTitle: 'Teacher Details',
            createTitle: 'Create new Teacher',
            apiService: teachersApiService,
            withImage: true,
            selectedItem: selectedTeacher,
            initItems: () => initTeachers(),
            rows: getRows(),
            actions: { hideDeleteBtn: true }
        }} />
    )
}

export default TeacherDetails;

const BirthdayRow = props => {
    const { selectedItem: selectedTeacher, updateSelectedItem } = props;

    return (
        <RowDetails label={'Date of birth'}>
            <Calendar value={ new Date(selectedTeacher.birthday)} onChange={(e) => updateSelectedItem('birthday', e.value)} monthNavigator yearNavigator yearRange="1900:2030" />
        </RowDetails>
    )
}

const SocialLinksRow = props => {
    const { selectedItem: selectedTeacher, updateSelectedItem } = props;
    const { facebook, instagram, youtube, website } = ( selectedTeacher && selectedTeacher.socialLinks ) || {};

    const onLinkChange = (value, key) => {
        const payload = {...selectedTeacher.socialLinks, [key]: value};
        updateSelectedItem('socialLinks', payload);
    }

    return (
        <RowDetails label={'Social Media Links'}>
            <InputGroup isHaveToStartWithHttps={true} value={facebook} onChange={(value) => onLinkChange(value, 'facebook')} icon={['fab', 'facebook']} placeholder={'https://'}/>
            <InputGroup isHaveToStartWithHttps={true} value={instagram} onChange={(value) => onLinkChange(value, 'instagram')} icon={['fab', 'instagram']} placeholder={'https://'}/>
            <InputGroup isHaveToStartWithHttps={true} value={youtube} onChange={(value) => onLinkChange(value, 'youtube')} icon={['fab', 'youtube']} placeholder={'https://'}/>
            <InputGroup isHaveToStartWithHttps={true} value={website} onChange={(value) => onLinkChange(value, 'website')} icon={'globe'} placeholder={'website'}/>
        </RowDetails>
    )
}

const PaymentInfoRow = (props) => {
    const { selectedItem: selectedTeacher, updateSelectedItem}  = props;
    const { paypal } = (selectedTeacher && selectedTeacher.paymentInfo) || {};

    return (
        <RowDetails label={'Payment Info'}>
            <InputText value={paypal} disabled placeholder={'Paypal'}/>
        </RowDetails>
    )
}

const InputGroup = (props) => {
    const { isHaveToStartWithHttps } = props;
    const [ err, setErr ] = useState(null);

    const onChange = (value) => {
        if (isHaveToStartWithHttps) {
            if (!value.startsWith('https://')) {
                setErr('Link must starts with : https://');
            } else {
                setErr(null);
            }
        }

        props.onChange(value)
    }

    return (
        <div className={'input-container'}>
            <div className="p-inputgroup p-col-12">
            <span className="p-inputgroup-addon">
                <FontAwesomeIcon icon={props.icon}/>
            </span>
                <InputText value={props.value ? props.value : ''} onChange={(e) => onChange(e.target.value)} placeholder={props.placeholder}/>
            </div>

            { isHaveToStartWithHttps && err && err.length > 0 && <p className="error-msg"> {err}</p> }
        </div>
    )
}