
import React from 'react';
import { connect } from 'react-redux';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

import './terms.scss';

import { globals } from '../../index';
import { dashboardApiService } from '../../service';


class EditTerms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subject: '',
            message: '',
            email: props.user.email
        };
    }

    componentDidMount() {

    }

    subjectOnChange = (event) => {
        this.setState({subject: event.target.value});
    }

    messageOnChange = (event) => {
        this.setState({message: event.target.value});
    }

    sendMessage = () => {
        const payload = {
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message
        }

        dashboardApiService.post(`contact`, payload).then((data) => {
            globals.growlRef.show({summary: `Sent!`});
        }).catch((error) => { });
    }

    render() {
        return (
            <div className="content-page">
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="card card-w-title" style={{'padding': '10px 20px 20px 20px', minHeight: '100%'}}>
                            <h3><b>Contact us</b></h3>
                            Subject: <InputTextarea rows={2} cols={70} defaultValue={this.state.subject} style={{width: '100%', border: '1px solid #a9a9a9', resize: 'none'}} onChange={this.subjectOnChange}/>
                            <br/><br/>
                            Message: <InputTextarea rows={14} cols={70} defaultValue={this.state.message} style={{width: '100%', border: '1px solid #a9a9a9', resize: 'none'}} onChange={this.messageOnChange}/>
                            <br /><br />
                            <Button label="Send" style={{backgroundColor: '#291630'}} onClick={this.sendMessage}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EditTerms);