import React, { useEffect } from 'react';
import { Button } from "primereact/button";

import { CopyToClipboardRow } from '../../shared/components/index';

const Dashboard = () => {
    const referralId = localStorage.getItem('referralId');
    const referralLink = `https://www.relove.com/?refid=${referralId}&type=teacher`;

    useEffect(() => {

    }, []);

    return (
        <div className="p-col-6">
            <div className="card card-w-title"> 
                <br/>
                <h1 className='p-card-title' style={{ textAlign:'center'}}>Welcome to Relove!</h1>

                <br/>

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <a href='https://drive.google.com/file/d/1orh-e6eBSRTY3ryIAYRkvqISOvcwzOeV/view' target='_blamk'><h1 style={{color: '#000000'}}>Course Creation Guide PDF</h1><Button label="Download" style={{marginBottom:'10px'}} className="p-button-success" /></a>
                    </div>
                </div>

                <br/>

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>Referral Link</h1>
                            <CopyToClipboardRow value={referralLink}/>
                    </div>
                </div>
            
            </div>

        </div>
    )
}

export default Dashboard;