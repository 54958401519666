import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useEffect, useRef, useState } from "react";
import { $DurationToMinutes } from "../../shared/pipes";
import { TeacherSelector } from "../../shared/components";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useSelector } from "react-redux";
import { $RtcFor } from "../../shared/dirctives";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Moment from 'react-moment';
import moment from "moment";
import ReactTooltip from 'react-tooltip';

export const VideoTable = (props) => {
    const DATE_FORMAT = 'DD/MM/YY';
    const [teacherFilter, setTeacherFilter] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const user = useSelector(state => state.user);
    const isTeacher = user.role === 'teacher';
    const dt = useRef(null);

    useEffect(() => {
        if (isTeacher) {
            setTeacherFilter(user.id);
        }}, []);

    const ownerTemplate = (rowData, column) => {
        return (
            <div>
                <TeacherSelector disabled value={rowData.teacherId}/>
            </div>
        )
    }
    const createdDateTemplate = (rowData, column) => {
        return (
            <div>
                { rowData.createdDate && <Moment format={DATE_FORMAT}>{rowData.createdDate}</Moment> }
            </div>
        )
    }

    const nameTemplate = (rowData) => {

        if (rowData.name.includes('uploading...')) {
            return (
                <div>
                    <p style={{color: '#c5c5c5'}}>
                        Video upload process complete and your video will be ready soon.
                        This process could take couple of minutes, please come back soon to check the status.
                    </p>
                </div>
            )
        } else {
            return (
                <div style={{overflow: 'hidden'}}>
                    <p>{rowData.name}</p>
                </div>
            )
        }
    }

    const getUseInMap = (mapId) => {
        const mapName = props.mapsIdsToDetails && props.mapsIdsToDetails[mapId] && props.mapsIdsToDetails[mapId].name;
        return `Course: ${mapName}`;
    }

    const usedInTemplate = (rowData, column) => {
        return (
            <PerfectScrollbar>
                {$RtcFor(rowData.usedInStages,
                    (data , i) => (

                        <div data-tip={getUseInMap(data.mapId)} key={i}>
                            <Chip avatar={<Avatar alt="" src={data.image}/>} label={data.name} />
                            <ReactTooltip effect="solid" />
                        </div>

                    )
                )}
            </PerfectScrollbar>
        )
    }

    const completionRateTemplate = (rowData, column) => {
        return (
            <div>
                <p>{rowData.analytics && rowData.analytics.completionRate}</p>
            </div>
        )
    }

    const viewsTemplate = (rowData, column) => {
        console.log(rowData);

        return (
            <div>
                <p>{rowData.analytics && rowData.analytics.views}</p>
            </div>
        )
    }

    const totalWatchedTimeInSecTemplate = (rowData, column) => {
        return (
            <div>
                <p>{rowData.analytics && $DurationToMinutes(rowData.analytics.totalWatchedTimeInSec)}</p>
            </div>
        )
    }

    const durationTemplate = (rowData, column) => {
        return (
            <div>
                <p> {$DurationToMinutes(rowData.duration)}</p>
            </div>
        )
    }

    const onVideoSelected = (video) => {
        props.onVideoSelected(video);
        setSelectedVideo(video)
    }

    const usedInFilter = (stagesArr, filter) => {
        if (!filter) return true;
        return (stagesArr && stagesArr.some(stage => stage.name.toLowerCase().includes(filter.toLowerCase()))) || false;
    }

    const dateFilter = (date, filter) => {
        if (!filter) return true;

        return (date && moment(date).format(DATE_FORMAT).includes(filter)) || false;
    }

    const ownerFilter = () => {
        const onChange = (value) => {
            dt.current.filter(value, 'teacherId', 'equals');
            setTeacherFilter(value);
        }

        const onClear = () => {
            dt.current.filter(null, 'teacherId', 'equals');
            setTeacherFilter(null);
        }

        return (
            <div className="p-grid p-align-center">
                <div className="p-col-10">
                    <TeacherSelector disabled={isTeacher} value={teacherFilter} onChange={(value) => onChange(value)}/>
                </div>

                <div className="p-col-2">
                    { teacherFilter && <i className="pi pi-undo" onClick={(e) => onClear()}></i> }
                </div>
            </div>)
    }

    return (
        <div className="video-table">
            <div className="card card-w-title" style={{'padding': '0px'}}>
                <DataTable className="p-datatable-borderless"
                           ref={dt}
                           value={props.videos}
                           selectionMode="single"
                           header={`Videos & Audios - ${props.videos.length}`}
                           paginator={true}
                           rows={5}
                           responsive={true}
                           selection={selectedVideo}
                           rowsPerPageOptions={[5,10,20,30,40,50]}
                           onSelectionChange={event => onVideoSelected(event.value)}>

                    <Column field="name" header="Name" body={nameTemplate} filter sortable={true}/>
                    <Column field="teacherId" header="Owner" body={ownerTemplate} filter filterElement={ownerFilter()}
                            sortable={true}/>
                    <Column style={{width:'20%'}} field="usedInStages" header="Used in" body={usedInTemplate} filter filterMatchMode={'custom'} filterFunction={usedInFilter}  sortable={true}/>
                    <Column field="analytics.completionRate" header="Completion Rate" body={completionRateTemplate} filter sortable={true}/>
                    <Column field="analytics.views" header="Views" body={viewsTemplate} filter sortable={true}/>
                    <Column field="analytics.totalWatchedTimeInSec" header="total watch time" body={totalWatchedTimeInSecTemplate} filter sortable={true}/>
                    <Column field="createdDate" header="Created Date" body={createdDateTemplate} filter filterMatchMode={'custom'} filterFunction={dateFilter} sortable={true}/>
                </DataTable>

            </div>
        </div>
    )
}
