import React, { useEffect, useState } from "react";
import { BaseCardDetails } from "../../shared/components";
import { categoriesApiService } from "../../service";
import { objectToFormData } from "../../shared/functions";

export const CategoryDetails = (props) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { isCreateMode } = props;

  useEffect(() => {
    setSelectedCategory(props.selectedCategory);
  }, [props.selectedCategory]);

  return (
    <BaseCardDetails
      {...{
        isCreateMode,
        itemName: "CATEGORY",
        editTitle: "Category Details",
        createTitle: "Create new Category",
        apiService: categoriesApiService,
        withImage: true,
        selectedItem: selectedCategory,
        initItems: (item) => props.initCategories(true),
        rows: [
          { component: "InputText", label: "Name", key: "name" },

          {
            component: "Spinner",
            label: "Order by",
            key: "orderBy",
            min: 0,
            showButtons: true,
            max: 100,
            type: "number",
            default: 1,
            isMandatoryFiled: false,
          },
        ],

        actions: {
          onBeforeCreate: (selectedItem, form) => {
            form = objectToFormData(selectedItem, form, []);
            return form;
          },
        },
      }}
    />
  );
};