import React, { useState, useEffect } from 'react';

import { referralsApiService } from '../../service/index';

const ReferralsAdmin = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        referralsApiService.get(``).then(({data}) => {
            setUsers(data);
        })
    }, []);

    return (
        <div className="p-col-12">
            <div className="card card-w-title"> 
                <br/>
                {users.map((users, index) => (
                    <React.Fragment key={index}>
                        <a href={`https://dashboard.relove.com/#/referral/${users[0]}`} style={{color: 'black'}}><div>{users[1]}</div></a><br/><br/>
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default ReferralsAdmin;