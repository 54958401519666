import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import AppTopbar from './AppTopbar';
import { AppBreadcrumb } from './AppBreadcrumb';
import { AppMenu } from './AppMenu';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { EmptyPage } from './components/EmptyPage';
import { authService } from "./service";
import { $RtcIfElse } from "./shared/dirctives";
import 'primereact/resources/primereact.min.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './App.css';

import Login from "./pages/Login";
import MapsPage from "./pages/Maps/Maps";
import ProfilePage from "./pages/Profile/Profile";
import CategoriesPage from "./pages/Category/Categories";
import EventsPage from "./pages/Events/Events";
import TeachersPage from './pages/Teachers/Teachers';
import UsersPage from './pages/Users/Users';
import ReferralsPage from './pages/Referrals/ReferralsPage';
import ReferralsAdmin from './pages/Referrals/ReferralsAdmin';
import ReferralsAdminPage from './pages/Referrals/ReferralsAdminPage';
import Dashboard from './pages/Dashboard/Dashboard';

import PaymentsPage from "./pages/Payments/Payments";
import PaymentsReferralPage from './pages/PaymentsReferral/PaymentsReferral';
import EditTerms from './pages/Terms/EditTerms';
import ShowTerms from './pages/Terms/ShowTerms';
import ShowTechnical from './pages/Terms/ShowTechnical';
import ContactUs from './pages/Terms/ContacUs';

import { ContentPage } from "./pages/Content/Content";
import { useSelector } from "react-redux";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { SettingsPage } from "./pages/Settings/Settings";


library.add(fab, faCheckSquare, faCoffee, faGlobe);

class App extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true,
            layoutMode: 'static',
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkTheme: false,
            menuActive: false,
            themeColor: 'blue',
            configDialogActive: false
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onThemeChange = this.onThemeChange.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.changeMenuMode = this.changeMenuMode.bind(this);
        this.changeMenuColor = this.changeMenuColor.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
        this.onConfigCloseClick = this.onConfigCloseClick.bind(this);
        this.onConfigClick = this.onConfigClick.bind(this);
    }

    componentDidMount() {
        Promise.all([
            authService.initCurrentUser(),
        ]).then(([isLoggedIn]) => {
            this.setState({isLoading: false});
            if (!isLoggedIn) {
                window.location.replace('?#/login');
            }
        }).catch(err => {
            window.location.replace('?#/login');
        })
    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            topbarMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay' && !this.isMobile()) {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        } else {
            if (this.isDesktop())
                this.setState({staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive});
            else
                this.setState({staticMenuMobileActive: !this.state.staticMenuMobileActive});
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({activeTopbarItem: null});
        else
            this.setState({activeTopbarItem: event.item});

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onConfigButtonClick(event) {
        this.configClick = true;
        this.setState({configDialogActive: !this.state.configDialogActive})
    }

    onConfigCloseClick() {
        this.setState({configDialogActive: false})
    }

    onConfigClick() {
        this.configClick = true;
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.configClick) {
            this.setState({configDialogActive: false});
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.configClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return (+width <= 1024 && +width > 640);
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeMenuMode(event) {
        this.setState({
            layoutMode: event.menuMode,
            staticMenuDesktopInactive: false,
            overlayMenuActive: false
        });
    }

    changeMenuColor(event) {
        this.setState({darkTheme: event.darkTheme})
        this.onThemeChange();
    }

    onThemeChange() {
        const themeLink = document.getElementById('theme-css');
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
        const themeTokens = themeFile.split('-');
        const themeName = themeTokens[1];
        const themeMode = themeTokens[2];
        const newThemeMode = (themeMode === 'dark') ? 'light' : 'dark';

        this.changeTheme({originalEvent: null, theme: themeName + '-' + newThemeMode});
    }

    changeTheme(event) {
        this.setState({themeColor: event.theme.split('-')[0]})
        this.changeStyleSheetUrl('layout-css', event.theme, 'layout');
        this.changeStyleSheetUrl('theme-css', event.theme, 'theme');
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);

        if (value.indexOf('dark') !== -1) {
            this.setState({darkTheme: true});
        } else {
            this.setState({darkTheme: false});
        }
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    replaceLink(linkElement, href) {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);

        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    render() {
        const layoutClassName = classNames('layout-wrapper', {
            'layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-slim': this.state.layoutMode === 'slim',
            'layout-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-mobile-active': this.state.staticMenuMobileActive,
            'layout-overlay-active': this.state.overlayMenuActive
        });
        const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);
        const AppTopbarWithRouter = withRouter(AppTopbar);

        return (
            $RtcIfElse(this.state.isLoading,
                (
                    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fdd8d2' }}>
                        <img src="assets/layout/images/heartpreloader.gif" alt=""/>
                    </div>
                ),

                (
                    <div className={layoutClassName} onClick={this.onDocumentClick}>
                        <AppTopbarWithRouter darkTheme={this.state.darkTheme} onThemeChange={this.onThemeChange}
                                             topbarMenuActive={this.state.topbarMenuActive}
                                             activeTopbarItem={this.state.activeTopbarItem}
                                             onMenuButtonClick={this.onMenuButtonClick}
                                             onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                                             onTopbarItemClick={this.onTopbarItemClick}/>

                        <div className='layout-menu-container' onClick={this.onMenuClick}>
                            <div className="layout-menu-content">
                                <div className="layout-menu-title">MENU</div>
                                <Menu {...{
                                    onRootMenuItemClick: this.onRootMenuItemClick,
                                    onMenuItemClick: this.onMenuItemClick,
                                    layoutMode: this.state.layoutMode,
                                    menuActive: this.state.menuActive}} />
                            </div>
                        </div>

                        <div className="layout-content">
                            <AppBreadCrumbWithRouter/>
                            <Routes />
                            {this.state.staticMenuMobileActive && <div className="layout-mask"></div>}
                        </div>
                    </div>
                ))
        );
    }
}

export default withRouter(App);

const Menu = (props) => {
    const {onRootMenuItemClick,onMenuItemClick ,layoutMode, menuActive} = props;
    const user = useSelector( state => state.user);
    const [menu, setMenu] = useState([]);
    const isTeacher = user.role === 'teacher';
    const createMenu = () => {
        const adminMenu = [
            {label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'},
            {label: 'Profile', icon: 'pi pi-fw pi-user', to: '/profile'},
            {label: 'Teachers', icon: 'pi pi-fw pi-id-card', to: '/teachers'},
            {label: 'Users', icon: 'pi pi-fw pi-users', to: '/users'},
            {label: 'Courses', icon: 'pi pi-fw pi-images', to: '/maps'},
            {label: 'Categories', icon: 'pi pi-fw pi-tags', to: '/categories'},
            {label: 'Payments', icon: 'pi pi-fw pi-tags', to: '/payments'},
            {label: 'Content', icon: 'pi pi-fw pi-video', to: '/content'},
            {label: 'Events', icon: 'pi pi-fw pi-images', to: '/events'},
            {label: 'Edit Terms', icon: 'pi pi-fw pi-list', to: '/terms'},
            {label: 'Settings', icon: 'pi pi-fw pi-cog', to: '/settings'},
            {label: 'Referrals', icon: 'pi pi-fw pi-users', to: '/referrals'},
            {label: 'Referral Payments', icon: 'pi pi-fw pi-tags', to: '/referral-payments'}
        ];

        const teacherMenu = [
            {label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'},
            {label: 'Courses', icon: 'pi pi-fw pi-images', to: '/maps'},
            {label: 'Profile', icon: 'pi pi-fw pi-user', to: '/profile'},
            {label: 'Content', icon: 'pi pi-fw pi-video', to: '/content'},
            {label: 'Events', icon: 'pi pi-fw pi-images', to: '/events'},
            {label: 'Payments', icon: 'pi pi-fw pi-tags', to: '/payments'},
            {label: 'Terms & Conditions', icon: 'pi pi-fw pi-list', to: '/terms'},
            {label: 'Technical Requirements', icon: 'pi pi-fw pi-cog', to: '/technical'},
            {label: 'Contact us', icon: 'pi pi-fw pi-comment', to: '/contact'},
            {label: 'Referrals', icon: 'pi pi-fw pi-users', to: '/referrals'},
            {label: 'Referral Payments', icon: 'pi pi-fw pi-tags', to: '/referral-payments'}
        ];

        isTeacher ? setMenu(teacherMenu) : setMenu(adminMenu);
    };

    useEffect(() => {
        createMenu();
    } , [])
    
    return (
        <>
            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} layoutMode={layoutMode} active={menuActive}/>
        </>
    )
}
const Routes = (props) => {
    const user = useSelector( state => state.user);

    const isTeacher = user.role === 'teacher';

    return (
        <div className="layout-content-container">
            {
                $RtcIfElse(isTeacher, (
                    // Teacher
                    <Switch>
                        <Route path="/" exact component={Dashboard}/>
                        <Route path="/login" exact component={Login}/>
                        <Route path="/maps" exact component={MapsPage}/>
                        <Route path="/content" exact component={ContentPage}/>
                        <Route path="/payments" exact component={PaymentsPage}/>
                        <Route path="/profile" exact component={ProfilePage}/>
                        <Route path="/empty" component={EmptyPage}/>
                        <Route path="/events" exact component={EventsPage}/>
                        <Route path="/terms" component={ShowTerms}/>
                        <Route path="/technical" component={ShowTechnical}/>
                        <Route path="/contact" component={ContactUs}/>
                        <Route path='/referrals' exact component={ReferralsPage} />
                        <Route path='/referral-payments' exact component={PaymentsReferralPage} />

                        <Route path="*" component={PageNotFound}/>
                    </Switch>
                ), (

                    // Admin
                    <Switch>
                        <Route path="/" exact component={Dashboard}/>
                        <Route path="/login" exact component={Login}/>
                        <Route path="/settings" exact component={SettingsPage}/>
                        <Route path="/categories" exact component={CategoriesPage}/>
                        <Route path="/maps" exact component={MapsPage}/>
                        <Route path='/users' exact component={UsersPage} />
                        <Route path="/content" exact component={ContentPage}/>
                        <Route path="/events" exact component={EventsPage}/>
                        <Route path="/payments" exact component={PaymentsPage}/>
                        <Route path='/teachers' exact component={TeachersPage}/>
                        <Route path="/profile" exact component={ProfilePage}/>
                        <Route path="/terms" exact component={EditTerms}/>
                        <Route path='/referrals' component={ReferralsAdmin} />
                        <Route path='/referral/:referral' component={ReferralsAdminPage} />
                        <Route path='/referral-payments' exact component={PaymentsReferralPage} />

                        <Route path="*" component={PageNotFound}/>
                    </Switch>
                ))
            }
        </div>
    )
}

const PageNotFound = () => {
    useEffect(() => { window.location.replace('#/notfound'); }, [])
    return ( <div></div> )
}