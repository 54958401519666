import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categoriesApiService, helperService, teachersApiService } from '../../service';
import { updateCategories, updateTeachers } from '../../redux/actions';
import { globals } from '../../index';
import { $RtcIf } from '../dirctives';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BaseCardDetails } from './BaseCardDetails.js';
import classNames from 'classnames';
import './components.scss';

import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import ReactTooltip from 'react-tooltip';
export const defImage = 'assets/layout/images/default_img.png';

const CategorySelector = (props) => {
    const categories = useSelector(state => state.categories);
    const dispatcher = useDispatch();

    useEffect(() => {
        if (!categories.length) {
            categoriesApiService.get('')
                .then(({data}) => {
                    dispatcher(updateCategories(data.data));
                })
        }
    })

    const categoryTemplate = (option) => {
            return (
                <div className='p-clearfix' style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <img alt={option.label} src={option.image} style={{ display: 'inline-block', margin: '5px 10px 0 5px', objectFit: 'cover', borderRadius: '50%' }} width='42' height='42'/>
                    <span style={{float: 'right', margin: '.5em .25em 0 0'}}>{option.name}</span>
                </div>
            )
    }

    return (
        <>
            <div className='category-selector' style={{position: 'relative', width: '100%'}}>
                <Dropdown
                    style={{position: 'relative', width: '100%'}}
                    optionLabel='name'
                    optionValue='id'
                    value={props.value}
                    itemTemplate={categoryTemplate}
                    options={categories || []}
                    onChange={(e) => {
                        props.onChange && props.onChange(e.value)
                    }}
                    placeholder='Select a Category'/>
            </div>
        </>
    )
}

const TeacherSelector = (props) => {
    const teachers = useSelector(state => state.teachers);
    const dispatcher = useDispatch();

    useEffect(() => {
        if (!teachers.length) {
            teachersApiService.get('')
                .then(({data}) => {
                    const teachersMapping = data.data.map(teacher => {
                        teacher.name = `${teacher.firstName} ${teacher.lastName}`;
                        return teacher;
                    })
                    dispatcher(updateTeachers(teachersMapping));
                })
        }
    })

    const teacherTemplate = (option) => {
        return (
            <div className='p-clearfix' style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <img alt={option.label} src={option.picture} style={{ display: 'inline-block', margin: '5px 10px 0 5px', objectFit: 'cover', borderRadius: '50%' }} width='42' height='42'/>
                <span style={{float: 'right', margin: '.5em .25em 0 0'}}>{option.firstName + ' ' + option.lastName}</span>
            </div>
        )
    }

    return (
        <>
            <div className='teacher-selector' style={{position: 'relative', width: '100%'}}>
                <Dropdown
                    filter
                    filterBy={'name'}
                    style={{position: 'relative', width: '100%'}}
                    optionLabel='name'
                    optionValue='id'
                    value={props.value}
                    disabled={props.disabled}
                    itemTemplate={teacherTemplate}
                    options={teachers || []}
                    onChange={(e) => {
                        props.onChange && props.onChange(e.value)
                    }}
                    placeholder='Select a Teacher'/>
            </div>
        </>
    )
}

const ImageSelector = React.forwardRef((props, ref) => {
    const onImageSelect = async (event) => {
        const compressedFile = await helperService.compressImage(event.files[0]);
        const file = ((compressedFile.size < event.files[0] && event.files[0].size) ? compressedFile : event.files[0]) || compressedFile;
        if (props.onImageSelect) props.onImageSelect(file);
        if (props.onFileChanged) props.onFileChanged(file);
    }

    return (
        <>
            <FileUpload ref={ref} disabled={false} customUpload={true} mode={props.mode || 'advanced'} name='file' onSelect={(e) => onImageSelect(e)} onUpload={(e) => props.onUpload && props.onUpload(e)} accept='image/*'/>
        </>
    )
});

const RowDetails = (props) => {
    let { showIf, isMandatoryFiled, showInColumn, style, tooltip } = props;

    let foryouifTip = false;
    let requirementTip = false;

    if (props.label === 'For you if') {
        tooltip = ''
        foryouifTip = true;
    }

    if (props.label === 'Requirement') {
        tooltip = ''
        requirementTip = true;
    }
    
    const randomId = (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    const tooltipId =`tooltip-row-details-${randomId}`;

    return (
        <>
            {
                showIf &&

                <>
                    <div className={classNames('p-col-12 p-md-4', {'p-md-12': showInColumn})} style={{textAlign: 'left', position: 'relative', ...style}}>
                        <label style={{fontWeight: 'bold'}} htmlFor='input'>{props.label}
                            { isMandatoryFiled && <span>*</span> }
                            
                            { tooltip &&
                                <React.Fragment>
                                    <i data-for={tooltipId} data-tip={tooltip} className='pi pi-info-circle' style={{'fontSize': '1em', marginLeft: 5, color: '#adadad'}}></i>
                                    <ReactTooltip id={tooltipId} place={'right'} effect='solid' />
                                </React.Fragment>
                            }

                            { foryouifTip &&
                                <React.Fragment>
                                    <i data-for={tooltipId} data-tip={tooltip} className='pi pi-info-circle' style={{'fontSize': '1em', marginLeft: 5, color: '#adadad'}}></i>
                                    <ReactTooltip id={tooltipId} place={'right'} effect='solid'>
                                        <p>This section boosts sales by helping your target students understand that they are the right person to take your course.</p>
                                        <p>Here you list different problems that they might be having and benefits that they might be seeking.</p>
                                        <p>For example:</p>
                                        <p>This is for you if:</p>
                                        <ul>
                                            <li>You are struggling to communicate with your partner</li>
                                            <li>You want to create more harmony in your relationship</li>
                                            <li>You enjoy learning tools that you can use again and again</li>
                                        </ul>
                                    </ReactTooltip>
                                </React.Fragment>
                            }

                            { requirementTip &&
                                <React.Fragment>
                                    <i data-for={tooltipId} data-tip={tooltip} className='pi pi-info-circle' style={{'fontSize': '1em', marginLeft: 5, color: '#adadad'}}></i>
                                    <ReactTooltip id={tooltipId} place={'right'} effect='solid'>
                                        <p>This section gives you the chance to be clear what is required of your students to take your course.</p>
                                        <p>It helps build trust with students who are deciding whether or not to take it,<br/>increasing the chances of the right students signing up. Meaning more happy customer and less unhappy ones.</p>
                                        <p>Examples can include:</p>
                                        <ul>
                                            <li>Clear space to move your body</li>
                                            <li>A private room for emotional release</li>
                                            <li>Good movement of your body</li>
                                            <li>Another person to practice with</li>
                                        </ul>
                                    </ReactTooltip>
                                </React.Fragment>
                            }
                        </label>
                    </div>
                    <div
                        className={classNames('p-col-12 p-md-8', {'p-md-12': showInColumn})}
                        style={{alignItems: 'center', display: 'flex', flexWrap: 'wrap'}}>
                        {props.children}
                    </div>
                </>
            }

        </>
    )
}

RowDetails.defaultProps = {
    showIf: true
}

const SelectImageRow = (props) => {
    const { isCreateMode, imageFile, setImageFile, formFileName, apiService, url, afterFileUpload } = props;
    const [displayUploadSpinner, setDisplayUploadSpinner] = useState(false);
    const imageSelector = useRef(null);

    const _onFileChanged = (file) => {
        setImageFile(file);
    }

    const _onUpload = async () => {
        if (imageFile) {
            setDisplayUploadSpinner(true);
            const file = imageFile;
            const formData = new FormData();
            const compressedFile = await helperService.compressImage(file);

            formData.append(formFileName || 'file', compressedFile, compressedFile.name);

            apiService.post(url, formData)
                .then(({data}) => {
                    globals.growlRef.show({severity: 'success', summary: `Image has been changed.`});
                    const image = data.data.image || data.data.picture;
                    setDisplayUploadSpinner(false);
                    imageSelector.current.clear();
                    setImageFile(null);
                    if (afterFileUpload) afterFileUpload(image)
                });
        }
    };

    return (
        <RowDetails label={'Select image'}>
            <ImageSelector ref={imageSelector} mode={'basic'} onFileChanged={(file) => _onFileChanged(file)} />
            
            {$RtcIf(!isCreateMode, (
                <>
                    <Button disabled={!imageFile} label='Update image' className='p-button-success' onClick={() => _onUpload()}/>
                    {displayUploadSpinner &&
                    <ProgressSpinner style={{width: '30px', height: '30px'}} strokeWidth='2' fill='#EEEEEE' animationDuration='.5s'/>}
                </>
            ))}
        </RowDetails>
    )
}

const CopyToClipboardRow = (props) => {
    const onCopy = () => {
        globals.growlRef.show({severity: 'success', summary: 'Copied.'})
    }

    return (
        <div className='copy-row' style={{position: 'relative', width: '100%', display: 'flex'}}>
            <InputText disabled value={props.value ? props.value : ''} style={{width: '100%'}}/>
            <CopyToClipboard text={props.value}>
                <Button className='p-button-success' label={'Copy'} onClick={() => onCopy()}/>
            </CopyToClipboard>
        </div>
    )
}

const ImageCellTemplate = (props) => {
    return (
        <div>
            <img style={{width: 50, height: 50}} src={props.src || defImage} alt=''/>
        </div>
    )
}

export { BaseCardDetails, CopyToClipboardRow, SelectImageRow, RowDetails, ImageSelector, TeacherSelector, CategorySelector, ImageCellTemplate }