import React from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

import './terms.scss';

import { globals } from '../../index';
import { dashboardApiService } from '../../service';



class EditTerms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            terms: '',
            technical: ''
        }
    }

    componentDidMount() {
        dashboardApiService.get(`getTeachersTerms`).then(({ data }) => {
            this.setState({terms: data.data.text});
        });

        dashboardApiService.get(`getTechnical`).then(({ data }) => {
            this.setState({technical: data.data.text});
        });
    }

    termsOnChange = (event) => {
        this.setState({terms: event.target.value});
    }

    technicalOnChange = (event) => {
        this.setState({technical: event.target.value});
    }

    saveTerms = () => {
        const payload = { text: this.state.terms };

        dashboardApiService.put(`updateTeachersTerms`, payload).then((data) => {
            globals.growlRef.show({summary: `Saved!`});
        }).catch((error) => { });
    }

    saveTechnical = () => {
        const payload = { text: this.state.technical };

        dashboardApiService.put(`updateTechnical`, payload).then((data) => {
            globals.growlRef.show({summary: `Saved!`});
        }).catch((error) => { });
    }

    render() {
        return (
            <div className="content-page">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{'padding': '10px 20px 20px 20px', minHeight: '100%'}}>
                            <h3><b>Terms & Conditions</b></h3>
                            <InputTextarea rows={14} cols={70} defaultValue={this.state.terms} style={{width: '50%', border: '1px solid #a9a9a9', resize: 'none'}} onChange={this.termsOnChange}/>
                            <br /><br />
                            <Button label="Save" onClick={this.saveTerms} style={{backgroundColor: '#291630'}}/>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <div className="card card-w-title" style={{'padding': '10px 20px 20px 20px', minHeight: '100%'}}>
                            <h3><b>Technical Requirements</b></h3>
                            <InputTextarea rows={14} cols={70} defaultValue={this.state.technical} style={{width: '50%', border: '1px solid #a9a9a9', resize: 'none'}} onChange={this.technicalOnChange}/>
                            <br /><br />
                            <Button label="Save" onClick={this.saveTechnical} style={{backgroundColor: '#291630'}}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditTerms;
