import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ImageCellTemplate } from '../../shared/components';

export const EventsTable = (props) => {
    const {events, selectedEvent, onSelectionChange} = props;

    const imageTemplate = (rowData, column) => {
        return (
            <ImageCellTemplate src={rowData.image} />
        )
    }

    const onCreate = () => {
        onSelectionChange({}, true);
    }

    const renderHeader = () => {
        return (
            <div className={'table-header'}>
                List of Events - {events.length}
                
                <div>
                    <Button icon="pi pi-plus" label="Create new Event" className="p-button-raised" onClick={() => onCreate()}/>
                </div>
            </div>
        );
    }

    return (
        <div className="events-table">
            <div className="card card-w-title" style={{'padding': '0px'}}>
                <DataTable className="p-datatable-borderless"
                           value={events}
                           selectionMode="single"
                           rowHover
                           header={renderHeader()}
                           paginator={true}
                           rows={5}
                           rowsPerPageOptions={[5,10,20,30,40,50]}
                           responsive={true}
                           selection={selectedEvent}
                           onSelectionChange={event => onSelectionChange(event.value)}>
                    <Column field="name" header="Name" filter sortable={true}/>
                    <Column field="image" header="Image" body={imageTemplate}/>
                </DataTable>
            </div>
        </div>

    )
}