
import React from 'react';
import { InputTextarea } from 'primereact/inputtextarea';

import './terms.scss';

import { dashboardApiService } from '../../service';


class EditTerms extends React.Component {
    constructor(props) {
        super(props);

        this.state = { technical: '' };
    }

    componentDidMount() {
        dashboardApiService.get(`getTechnical`).then(({ data }) => {
            this.setState({technical: data.data.text});
        });
    }

    render() {
        return (
            <div className="content-page">
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="card card-w-title" style={{'padding': '10px 20px 20px 20px', minHeight: '100%'}}>
                            <h3><b>Technical Requirements</b></h3>
                            <InputTextarea rows={20} cols={70} value={this.state.technical} style={{width: '100%', border: '1px solid #a9a9a9', resize: 'none'}}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditTerms;
