import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { usersApiService } from '../../service/index';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CreatedDateColumn } from "../../shared/tableColumns";

const ReferralsAdminPage = () => {
    let { referral } = useParams();

    const [users, setUsers] = useState([]);

    useEffect(() => {
        usersApiService.post(`referrals/${referral}`).then(({data}) => {
            setUsers(data);
        })
    }, []);

    return (
        <div className="users">
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title"> 
                            <DataTable className="p-datatable-borderless" value={users} paginator={true} autoLayout={true} rows={10} responsive={true} rowsPerPageOptions={[10,50,100]}>
                                <Column field="createdDate" header="Date" body={CreatedDateColumn.createdDateTemplate} filter filterMatchMode={'custom'} filterFunction={CreatedDateColumn.dateFilter} sortable={true}/>
                                <Column field="type" header="Type" filter sortable={true}/>
                                <Column field="landings" header="Landings" sortable={true}/>
                                <Column field="registrations" header="Registrations" sortable={true}/>
                                <Column field="purchases" header="Purchases" sortable={true}/>
                                <Column field="total" header="Total" sortable={true}/>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReferralsAdminPage;