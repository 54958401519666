import React, { useEffect, useState } from "react";
import { $RtcFor, $RtcIfElse } from "../../shared/dirctives";
import { BaseCardDetails, defImage, RowDetails } from "../../shared/components";
import { videoApiService } from "../../service";
import { $DurationToMinutes } from "../../shared/pipes";
import Moment from "react-moment";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import ReactTooltip from "react-tooltip";

import "./content.scss";
import { Chart } from "primereact/chart";
import { globals } from "../../index";

export const VideoDetails = (props) => {
  const [selectedVideoDraft, setSelectedVideoDraft] = useState(null);

  useEffect(() => {
    setSelectedVideoDraft(props.selectedVideo);
  }, [props.selectedVideo]);

  const calcSize = (size) => size && (size / 1000000).toFixed(2) + " Mb";
  const getRows = () => [
    {
      component: "InputText",
      label: "Name",
      key: "name",
      disabled: isUploading,
      tooltip: "Work name (Not shown to users)",
    },
    {
      component: "Checkbox",
      label: "Reviewed by editor",
      key: "reviewed",
      onlyForAdmin: true,
      default: false,
      isMandatoryFiled: false,
    },
    {
      component: "InputText",
      label: "Display Name",
      key: "displayName",
      disabled: isUploading,
      tooltip: "Title of video to show to the user",
    },
    {
      component: "InputText",
      label: "Type",
      key: "contentType",
      disabled: true,
    },
    {
      component: "InputText",
      label: "Size",
      key: "size",
      pipe: calcSize,
      disabled: true,
    },
    {
      component: "InputText",
      label: "Duration",
      key: "duration",
      pipe: $DurationToMinutes,
      disabled: true,
    },
    {
      component: "TeacherSelector",
      label: "Owner",
      key: "teacherId",
      disabled: isUploading,
    },
    {
      component: "InputTextarea",
      label: "Comments",
      key: "comments",
      autoResize: true,
      rows: 3,
      cols: 30,
      disabled: isUploading,
      tooltip: "Not shown to users",
    },
    {
      component: "custom",
      renderFunc: UsedInStagesRow,
      stateFromSource: { mapsIdsToDetails: props.mapsIdsToDetails },
    },
    { component: "custom", renderFunc: CreatedDateRow, stateFromSource: {} },
    { component: "custom", renderFunc: AnalyticsRow, stateFromSource: {} },
  ];

  const { initVideos } = props;
  const isUploading =
    selectedVideoDraft && selectedVideoDraft.name.includes("uploading..");
  const isAudio =
    selectedVideoDraft && selectedVideoDraft.contentType.includes("audio");
  
  return (
    <BaseCardDetails
      {...{
        itemName: "VIDEO",
        imageKeyName: "image",
        editTitle: "Video Details",
        createTitle: "",
        apiService: videoApiService,
        withImage: isAudio,
        CustomHeader: CardVideoHeader,
        selectedItem: selectedVideoDraft,
        initItems: () => initVideos(),
        rows: getRows(),
        actions: {
          isSaveBtnDisabled: isUploading,
          onBeforeDelete: (item) => {
            let isCanRemoveItem = true;
            if (item.usedInStages && item.usedInStages.length) {
              isCanRemoveItem = false;
              globals.growlRef.show({
                severity: "info",
                summary: `To remove this content, please remove it first from any stage that using it.`,
                life: 10000,
              });
            }

            return isCanRemoveItem;
          },
          onAfterDelete: (resonse) => setSelectedVideoDraft(null),
        },
      }}
    />
  );
};

const CardVideoHeader = (props) => {
  // isCreateMode, editTitle, createTitle, imageKeyName
  const { selectedItem } = props;
  const isAudio =
    selectedItem &&
    selectedItem.contentType &&
    selectedItem.contentType.includes("audio");
  return (
    <div className="p-col-12 card-header">
      <>
        <div>
          {$RtcIfElse(
            !isAudio,
            <video
              style={{ borderRadius: "20px", border: "3px solid" }}
              width="auto"
              height="200"
              controls
              src={selectedItem.url}
              type={selectedItem.contentType}
            ></video>,
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                style={{ marginBottom: "10px" }}
                src={selectedItem.image || defImage}
                alt=""
              />
              <audio
                src={selectedItem.url}
                type={selectedItem.contentType}
                width="auto"
                controls
                height="200"
              ></audio>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

const UsedInStagesRow = (props) => {
  const { selectedItem: selectedVideoDraft } = props;
  const { mapsIdsToDetails } = props.stateFromSource;

  const getUseInMap = (mapId) => {
    const mapName =
      mapsIdsToDetails &&
      mapsIdsToDetails[mapId] &&
      mapsIdsToDetails[mapId].name;
    return `Course: ${mapName}`;
  };

  return (
    <RowDetails label={"Used in stages"}>
      {/*onClick={handleClick}*/}
      {$RtcFor(selectedVideoDraft.usedInStages, (data, i) => (
        <div data-tip={getUseInMap(data.mapId)} key={i}>
          <Chip avatar={<Avatar alt="" src={data.image} />} label={data.name} />

          <ReactTooltip effect="solid" />
        </div>
      ))}
    </RowDetails>
  );
};

const CreatedDateRow = (props) => {
  const { selectedItem: selectedVideoDraft } = props;
  return (
    <RowDetails label={"Uploaded Date"}>
      {selectedVideoDraft.createdDate && (
        <Moment format={"DD/MM/YY h:mm a"}>
          {selectedVideoDraft.createdDate}
        </Moment>
      )}
    </RowDetails>
  );
};

const AnalyticsRow = (props) => {
  const { selectedItem: selectedVideo } = props;

  if (!selectedVideo.analytics) return <div></div>;

  const getLabels = () => {
    const cellWeight =
      selectedVideo.duration /
      selectedVideo.analytics.watchBehaviorGraph.length;

    const arr = [];

    for (
      let i = 0;
      i < selectedVideo.analytics.watchBehaviorGraph.length;
      i++
    ) {
      const index = i + 1;
      arr[i] = $DurationToMinutes(index * cellWeight);
    }
    return arr;
  };

  const mapToCompletionsRates = (watchBehaviorGraph) =>
    watchBehaviorGraph.map((d) =>
      Math.round(100 * (d / selectedVideo.analytics.uniqueViews))
    );

  const lineData = {
    labels: getLabels(),
    datasets: [
      {
        label: "Completion %",
        data: [
          ...mapToCompletionsRates(selectedVideo.analytics.watchBehaviorGraph),
        ],
        fill: true,
        borderColor: "#03A9F4",
      },
      // {
      //     label: 'Second Dataset',
      //     data: [28, 48, 40, 19, 86, 27, 90],
      //     fill: false,
      //     borderColor: '#FFC107'
      // }
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 10,
            max: 100,
          },
        },
      ],
    },
  };

  return (
    <RowDetails
      label={"Analytics"}
      showIf={selectedVideo.analytics}
      showInColumn
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LabelInfo
            title={"Total views"}
            text={selectedVideo.analytics.views}
          />
          <LabelInfo
            title={"Unique views"}
            text={selectedVideo.analytics.uniqueViews}
          />
          <LabelInfo
            title={"Completion"}
            text={selectedVideo.analytics.completionRate + " %"}
          />
          <LabelInfo
            title={"Total watch time"}
            text={$DurationToMinutes(
              selectedVideo.analytics.totalWatchedTimeInSec
            )}
          />
        </div>
        <Chart type="line" options={options} data={lineData} />
      </div>
    </RowDetails>
  );
};

const LabelInfo = ({ title, text }) => (
  <div>
    <label>{title} :</label>
    <span style={{ fontWeight: "bold" }}>{text}</span>
  </div>
);
