import React from 'react';
import './payments.scss';
import { paymentsApiService} from '../../service';
import { PaymentsTable } from './PaymentsTable';
import { PaymentsDetails } from './PaymentsDetails';

class PaymentsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedPayment: null,
            payments: [],
            isCreateMode: false,
        }
    }

    onSelectionChange(selectedPayment) {
        this.setState({ selectedPayment })
    }

    componentDidMount() {
        this.initPayments();
    }

    initPayments(clearSelected = false) {
        paymentsApiService.get('')
            .then(({data}) => {
                this.setState({payments: data.data})
                if (clearSelected) this.setState({selectedCategory: null});
            })
    }

    render() {
        const {selectedPayment, payments, isCreateMode } = this.state;
        return (
            <div className='payments-page'>
                <div className='p-grid'>
                    <div className='p-col-6'>
                        <PaymentsTable {...{ payments, selectedPayment, onSelectionChange: (selectedCategory) => this.onSelectionChange(selectedCategory) }} />
                    </div>

                    <div className='p-col-6'>
                        <div className='card card-w-title' style={{'padding': '20px', minHeight: '100%'}}>
                            <PaymentsDetails {...{ selectedPayment, isCreateMode, initPayments: (clearSelected) => this.initPayments(clearSelected) }}/>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default PaymentsPage;