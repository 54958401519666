import React, { useEffect, useState } from 'react';
import { BaseCardDetails } from '../../shared/components';
import { eventsApiService } from '../../service';
import { objectToFormData } from '../../shared/functions';

export const EventsDetails = (props) => {
	const [selectedEvent, setSelectedEvent] = useState(null);
	const { isCreateMode } = props;

	const ownerId = JSON.parse(localStorage.getItem('auth_user')).userId;

	useEffect(() => {
		setSelectedEvent(props.selectedEvent);
	}, [props.selectedEvent]);

	return (
		<BaseCardDetails
			{...{
				isCreateMode,
				itemName: 'EVENTS',
				editTitle: 'Events Details',
				createTitle: 'Create new Event',
				apiService: eventsApiService,
				withImage: true,
				selectedItem: selectedEvent,
				initItems: (item) => props.initEvents(true),
				
				rows: [
					{ component: 'ShowText', label: 'Owner', key: 'owner', disabled: true },
					{ component: 'InputText', label: 'Name', key: 'name', isMandatoryFiled: true },
					{ component: 'Checkbox', label: 'Published', key: 'published', onlyForAdmin: true },
					{ component: 'EventDate', label: 'Start Date', key: 'date', isMandatoryFiled: true },
					{ component: 'EventDate', label: 'End Date', key: 'endDate', isMandatoryFiled: true },
					{ component: 'InputText', label: 'City', key: 'city', isMandatoryFiled: true },
					{ component: 'InputText', label: 'Country', key: 'country', isMandatoryFiled: true },
					{ component: 'InputText', label: 'Link', key: 'link', isMandatoryFiled: true },
					{ component: 'ImageUploadDescription', label: '' }
				],

				actions: {
					onBeforeCreate: (selectedItem, form) => {
						form = objectToFormData(selectedItem, form, []);
						form.append('ownerId', ownerId);
						return form;
					},
				},
			}}
		/>
	);
};