import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CreatedDateColumn } from '../../shared/tableColumns';

export const PaymentsReferralTable = (props) => {
    const {payments, selectedPayment, onSelectionChange} = props;

    const renderHeader = () => {
        return (
            <div className={'table-header'}>
                List of Payments - {payments.length}
            </div>
        );
    }

    return (
        <div className='payments-table'>
            <div className='card card-w-title' style={{'padding': '0px'}}>
                <DataTable className='p-datatable-borderless'
                           value={payments} selectionMode='single' rowHover header={renderHeader()} paginator={true} rows={5} rowsPerPageOptions={[5,10,20,30,40,50]}
                           responsive={true} selection={selectedPayment} onSelectionChange={event => onSelectionChange(event.value)}>
                    <Column field='createdDate' header='Created Date' filter body={CreatedDateColumn.createdDateTemplate} filterMatchMode={'custom'} filterFunction={CreatedDateColumn.dateFilter} sortable={true}/>
                    <Column field='amount' header='Amount' filter sortable={true}/>
                    <Column field='currency' header='Currency' filter sortable={true}/>
                </DataTable>
            </div>
        </div>
    )
}