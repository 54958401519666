import axios from 'axios';
import * as Promise from 'bluebird';
import { authService }  from './index';
import {globals} from "../index";

class HttpService {
    axios;

    constructor(httpInterceptingHandler, httpResponseHandler, httpResponseErrorHandler) {
        this.axios = axios.create();
        this.axios.interceptors.request.use(httpInterceptingHandler);
        this.axios.interceptors.response.use(httpResponseHandler, httpResponseErrorHandler);
    }

    get(url , params) {
        return this.axios.get(url, {params});
    }

    post(url, payload) {
        return this.axios.post(url, payload)
    }

    put(url, payload) {
        return this.axios.put(url, payload)
    }

    delete(url, data) {
        return this.axios.delete(url, {data})
    }
}

const httpInterceptingHandler = async (config) => {
    await authService.accessTokenHandler(config);
    return config;
}

const httpResponseHandler = (response) => {
    if (response && response.data) {
        const {data} = response;
        if (data.message) {
            globals.growlRef.show({severity: 'info', life: 7000, summary: 'Note!', detail: (typeof data.message === 'string') ? data.message  : ''});
        }
    }
    return response;
}

const httpResponseErrorHandler = (data) => {
    const response = data && data.response;
    if (response) {
        const {data: resp} = response;
        if (resp.error) {
            if (!(resp.code === 'is_not_auth')) globals.growlRef.show({severity: 'error', summary: 'Error Message', detail: (typeof resp.error === 'string') ? resp.error  : ''});
            return Promise.reject(resp.error);
        }

    } else {
        return Promise.reject();
    }
}

const httpService = new HttpService(httpInterceptingHandler,httpResponseHandler, httpResponseErrorHandler);
export default httpService;